import { useState, useEffect } from "react";
import classes from "../../styles/login.module.css";
import { Button, Typography, Form } from "antd";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
const { Text } = Typography;
const MobileOtp = () => {
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setResend(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);
  const handleChange = (otp) => {
    setOtp(otp);
  };
  return (
    <div className={classes["mobile-container"]}>
      <div className={classes["top-left"]}></div>
      <div className={classes["bottom-right"]}></div>
      <div
        className={classes["mobile-content"]}
        style={{ margin: "auto", marginTop: "20vh" }}
      >
        <div>
          <Text className={classes["welcome"]}>Welcome Back</Text>
        </div>
        <Form name="basic" autoComplete="off">
          <div style={{ marginTop: "32px" }}>
            <Text className={classes["enter"]}>Enter OTP</Text>
          </div>
          <Form.Item
            style={{
              marginTop: "16px",
              marginBottom: "0px",
            }}
          >
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span></span>}
              inputStyle={classes.inputStyle}
            />
          </Form.Item>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Text className={classes["enter"]} style={{ marginTop: "6px" }}>
                Resend OTP in {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Text>
            </div>
            <div>
              <Button
                type="link"
                style={{ padding: "0", color: "#FD8C28" }}
                className={classes["enter"]}
              >
                Resend OTP
              </Button>
            </div>
          </div>
          <Form.Item style={{ marginTop: "32px" }}>
            <Link to="/login">
              <Button type="primary" style={{ width: "100%" }}>
                Log In
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default MobileOtp;

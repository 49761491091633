import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Radio, Slider, Space } from "antd";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const { TextArea } = Input;

const RateModel = ({ open, setOpen }) => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const min = 0;
  const max = 5;
  const mid = Number(((max - min) / 2).toFixed(5));
  const preColorCls = value >= mid ? "" : "icon-wrapper-active";
  const nextColorCls = value >= mid ? "icon-wrapper-active" : "";

  return (
    <Modal
      title="Rate Consumer"
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => setOpen(false)}>
          Save
        </Button>,
      ]}
      centered
    >
      <div className="icon-wrapper">
        <FrownOutlined className={preColorCls} />
        <Slider
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
          marks={{
            0: "0",
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
          }}
          max={5}
        />
        <SmileOutlined className={nextColorCls} />
      </div>
    </Modal>
  );
};

export default RateModel;

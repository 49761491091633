import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { fetchUser } from "./User/UserActions";
import { connect } from "react-redux";
import Loader from "./Components/Loader/Loader";
import PrivateRoute from "./helper/privateRoute";

// Routes Components Imports
import Home from "./Components/Home";
import Login from "./Components/Login";
import OtpPage from "./Components/Otp";
import ConsumerList from "./Components/ConsumerList";
import Signup from "./Components/Signup";
import Subscription from "./Components/Subscription";
import Policy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Cancellation from "./Components/Cancellation";
import NewPage from "./Components/NewPage";
import Profile from "./Components/Profile";
import ProductList from "./Components/ProductList";
import AddProduct from "./Components/AddProduct";
import SubHistory from "./Components/SubHistory";
import Invoice from "./Components/InvoicePDF";
import Checkout from "./Components/Checkout";
import SubSuccess from "./Components/SubSuccess";
// End Routes Components Imports

function App(props) {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      props?.userDetails && props?.fetchUsers();
    }
  }, []);
  if (props?.loading) {
    return <Loader />;
  }
  return (
    <Router>
      <Routes>
        {/* Open Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp/:token" element={<OtpPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cancellation-policy" element={<Cancellation />} />
        <Route path="/search/:role" element={<ConsumerList />} />
        {/* End Open Routes */}

        {/* Private Routes  */}
        <Route element={<PrivateRoute />}>
          <Route path="/details/:id" element={<NewPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/product/list" element={<ProductList />} />
          <Route path="/product/add" element={<AddProduct />} />
          <Route path="/subscription_history" element={<SubHistory />} />
          <Route path="/invoice/:id" element={<Invoice />} />
          <Route path="/checkout/:id/:orderId" element={<Checkout />} />
          <Route path="/sub_success" element={<SubSuccess />} />
        </Route>
        {/* End Private Routes  */}
      </Routes>
    </Router>
  );
}

const mapStateToProps = (state) => {
  let userDetails = state.user.user;
  let loading = state.user.loading;

  return {
    userDetails,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (params) => dispatch(fetchUser(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

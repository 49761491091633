import { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Button, Typography, Form, message } from "antd";
import classes from "../../styles/otp.module.css";
import Navbar from "../Navbar/index";
// import OTPInput, { ResendOTP } from "otp-input-react";
import OtpInput from "react-otp-input";
import MobileOtp from "./MobileOtp";
import axios from "axios";
import { connect } from "react-redux";
import { login } from "../../User/UserActions";

const { Text } = Typography;
const OtpPage = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const { token } = useParams();

  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    const obj = {
      otp: otp,
      token: token,
    };
    setLoading(true);
    axios
      .post("/user/verifylogin", obj)
      .then((res) => {
        setLoading(false);
        console.log(res);
        props.fetchUser(res.data);
        if (searchParams.get("page") === "signup") {
          navigate("/subscription");
        } else {
          navigate("/product/list");
        }
        window.location.reload();
        // props.history.push('/home');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Invalid OTP");
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setResend(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const resend_otp = () => {
    axios
      .post("/user/resendOtp", {
        token: token,
      })
      .then((res) => {
        console.log(res);
        // setSearchParams({
        //   token: res.data.token,
        // });
        message.success("OTP sent successfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong. Please try again later.");
      });
    setSeconds(30);
    setMinutes(1);
    setResend(true);
  };

  const enterLogin = (event) => {
    if (event.keyCode === 13) {
      submitHandler();
    }
  };

  return (
    <div>
      <div className={classes["main-container"]}>
        <Navbar login={true} />
        <div className={classes["container"]}>
          <div className={classes["left-side"]}></div>
          <div className={classes["top-left"]}></div>
          <div className={classes["bottom-right"]}></div>
          <div className={classes["right-side"]}>
            <div>
              <Text className={classes["welcome"]}>Login</Text>
            </div>
            <Form name="basic" autoComplete="off">
              <div style={{ marginTop: "32px" }}>
                <Text className={classes["enter"]}>Enter OTP</Text>
              </div>
              <Form.Item
                style={{
                  marginTop: "16px",
                  marginBottom: "0px",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span></span>}
                  inputStyle={classes.inputStyle}
                />
              </Form.Item>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Text
                    className={classes["enter"]}
                    style={{ marginTop: "6px" }}
                  >
                    Resend OTP in {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </Text>
                </div>
                <div>
                  <Button
                    type="link"
                    style={{ color: !resend ? "#FD8C28" : "#C4C4C4" }}
                    className={classes["enter"]}
                    disabled={resend}
                    onClick={() => resend_otp()}
                  >
                    Resend OTP
                  </Button>
                </div>
              </div>
              <Form.Item style={{ marginTop: "32px" }}>
                {/* <Link to="/login"> */}
                <Button
                  type="primary"
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  onClick={submitHandler}
                  loading={loading}
                  disabled={loading}
                >
                  Log In
                </Button>
                {/* </Link> */}
              </Form.Item>
            </Form>
          </div>
        </div>
        {/* <div className={classes["login-mobile"]}>
          <MobileOtp />
        </div> */}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id) => dispatch(login(id)),
  };
};

export default connect(null, mapDispatchToProps)(OtpPage);

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Footer from "../Footer";
import Content from "../Content";
import Navbar from "../Navbar";
import terms from "../Content/Terms.md";

const Terms = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    fetch(terms)
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        setText(data);
      });
  }, [text]);

  return (
    <div>
      <Navbar />
      <div
        style={{
          marginTop: "30px",
        }}
      >
        <Content data={text} />
      </div>
      <Footer />
    </div>
  );
};

export default Terms;

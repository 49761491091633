import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import classes from "./subHistory.module.css";
import { Button, Divider, Empty } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "../InvoicePDF";
import { pdf } from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Loader from "../Loader/Loader";

const color = {
  created: "#075C98",
  active: "#07981E",
  expired: "#353535",
  failed: "#D32F2F",
};

const data = [
  {
    _id: "1",
    type: "National Prime",
    total: "₹ 1000",
    purchase_date: "12/12/2020",
    expiry_date: "12/12/2021",
    status: "Ongoing",
  },
  {
    _id: "2",
    type: "National Prime",
    total: "₹ 1000",
    purchase_date: "12/12/2020",
    expiry_date: "12/12/2021",
    status: "Expired",
  },
];

const SubHistory = () => {
  // const [invoiceData, setInvoiceData] = useState({});/
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = () => {
    setLoading(true);
    axios.get("/subscriptionHistory").then((res) => {
      console.log(res?.data?.subscriptions);
      setLoading(false);
      if (
        res?.data?.subscriptions === undefined ||
        res?.data?.subscriptions?.length === 0
      ) {
        return setHistory([]);
      }
      setHistory(res?.data?.subscriptions);
    });
  };

  const generatePDF = async (id) => {
    const invoiceData = await fetchInvoice(id);
    console.log(invoiceData);
    const asPdf = await pdf(<Invoice data={invoiceData} />).toBlob();
    const blob = new Blob([asPdf], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  };

  const fetchInvoice = async (id) => {
    const dat = await axios.get(`/invoice?subscriptionid=${id}`);
    return dat.data;
  };

  return (
    <>
      <Navbar />
      <div className={classes["desktop-container"]}>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "24px",
          }}
        >
          Subscription History
        </div>
        <Divider
          style={{
            marginBottom: "20px",
            marginTop: "12px",
          }}
        />
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",

              width: "100%",
            }}
          >
            <Loader />
          </div>
        )}
        {history?.length === 0 && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Empty />
          </div>
        )}
        {history?.map((item) => {
          return (
            <div className={classes["subs_card_container"]}>
              <div className={classes["sub_card_details"]}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {item?.plan?.name}
                </div>
                <div>Total: {item?.plan?.price}</div>
              </div>
              <div className={classes["sub_card_details"]}>
                <div>Purchase Date:</div>
                <div>
                  {moment(item?.payment_done_date).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className={classes["sub_card_details"]}>
                <div>Expiry Date:</div>
                <div>{moment(item?.end_date).format("DD/MM/YYYY")}</div>
              </div>
              <div className={classes["sub_card_details"]}>
                <div
                  style={{
                    textTransform: "capitalize",
                    color: color[item?.status],
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {item?.status}
                </div>
              </div>
              <div className={classes["sub_card_details_last"]}>
                <Button
                  className={classes["desktop-content-button"]}
                  size="large"
                  style={{
                    width: "100%",
                    textDecoration: "none",
                    width: "100%",
                  }}
                  disabled={
                    item?.status === "failed" ||
                    item?.status === "created" ||
                    item?.isFreeSubs === true
                      ? true
                      : false
                  }
                  onClick={() => generatePDF(item?._id)}
                >
                  <DownloadOutlined />
                  Invoice
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
};

export default SubHistory;

import { useEffect, useState } from "react";
import Navbar from "../Navbar/index";
import classes from "../../styles/addProduct.module.css";
import {
  Typography,
  Upload,
  Input,
  Divider,
  Form,
  Select,
  InputNumber,
  Button,
  message,
  Modal,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Avatar, UserDesktop } from "../../assets";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Footer from "../Footer";

const Text = Typography;

const AddProduct = ({ userDetails }) => {
  const [data, setData] = useState({
    photo: [],
  });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (searchParams?.get("id")) {
      fetchProduct();
    }
    if (searchParams.get("showAddNotification") === "true") {
      message.info("Please add your product details");
    }
  }, [searchParams?.get("id")]);
  const fetchProduct = () => {
    console.log(searchParams?.get("id"));
    axios
      .get(`/product/getAProduct?id=${searchParams?.get("id")}`)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };
  const ownership = ["Proprietorship", "Partnership", "Private Limited"];
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const onSaveProfile = () => {
    console.log(data);
    if (searchParams?.get("id")) {
      axios
        .post("/product/update", {
          id: data?._id,
          productData: data,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200)
            message.success("Product Updated Successfully");
          else message.error("Something went wrong");
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong");
        });
    } else {
      axios
        .post("/product/create", data)
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            message.success("Product Created Successfully");
            navigate(`/product/add?id=${res?.data?.data?._id}`);
          } else message.error("Something went wrong");
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong");
        });
    }
    // data.companyLogo = imageUrl;
    // delete data?.isActive;
    // axios
    //   .post(`/user/update/`, {
    //     userBody: data,
    //   })
    //   .then(() => {
    //     fetchUser();
    //     message.success("Lab Information Updated Succesfully");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     message.error("Error Updating The Lab Information");
    //   });
  };
  const addImage = (imageUrl) => {
    const images = data?.photo;
    images.push(imageUrl);
    setData({ ...data, images });
    onSaveProfile();
  };
  const deleteImage = (indexx = previewImage) => {
    const images = data?.photo;
    images.splice(indexx, 1);
    setData({ ...data, images });
    setPreviewImage(null);
    setPreviewOpen(false);
    onSaveProfile();
  };
  const upload = async (e) => {
    const file = e.target.files[0];
    const fileType = file?.type?.split("/")[1];
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    const imageName = data?._id + rand;
    const folderName = "product_images";
    const url = await axios.get(
      `/profiles3Url?fileType=${fileType}&imageName=${imageName}&folderName=${folderName}`
    );
    // const { data } = url;
    await fetch(url?.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": file?.type,
      },
      body: file,
    });
    const imageUrl = `https://indhanbazaar1.s3.ap-south-1.amazonaws.com/${folderName}/${imageName}.${fileType}`;
    addImage(imageUrl);
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className={classes["desktop-container"]}>
        <div className={classes["top-left"]}></div>
        <div className={classes["bottom-right"]}></div>
        <Text
          className={classes["desktop-left-side-main-Text"]}
          style={{
            marginBottom: "28px",
          }}
        >
          {userDetails?.role === "consumer"
            ? "List a New Requirement"
            : "List a New Product"}
        </Text>
        <div className={classes["desktop-content"]}>
          <div className={classes["desktop-left-side"]}>
            <div className={classes["desktop-left-side-content"]}>
              <div style={{ marginBottom: "28px", width: "100%" }}>
                <div
                  style={{
                    width: "98%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "100%",
                    }}
                  >
                    Product Photos
                  </Text>
                  <div className={classes["divider-op"]}>
                    <Divider
                      style={{ marginTop: "12px", marginBottom: "0px" }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "16px",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                {data?.photo?.length > 0 &&
                  data?.photo?.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={item}
                          style={{
                            width: "116px",
                            height: "116px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPreviewImage(index);
                            setPreviewOpen(true);
                          }}
                        ></img>
                      </div>
                    );
                  })}
                {data?.photo?.length == 0 && <Avatar />}
              </div>
              {searchParams.get("id") && data?.photo?.length < 4 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  <Input
                    type="file"
                    style={{
                      width: "60%",
                    }}
                    accept="image/png, image/jpeg"
                    onChange={(e) => upload(e)}
                  />
                </div>
              )}
              <Modal
                open={previewOpen}
                title="Preview Image"
                footer={[
                  <Button
                    danger
                    type="primary"
                    onClick={() => deleteImage(previewImage)}
                  >
                    Delete
                  </Button>,
                ]}
                onCancel={handleCancel}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={data?.photo && data?.photo[previewImage]}
                  />
                </div>
              </Modal>
            </div>
          </div>
          <div>
            <Divider type="vertical" style={{ height: "100%" }} />
          </div>
          <div className={classes["desktop-right-side"]}>
            <div>
              <div>
                <div
                  style={{
                    width: "98%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Product Details
                  </Text>
                  <div className={classes["divider-op"]}>
                    <Divider
                      style={{ marginTop: "12px", marginBottom: "0px" }}
                    />
                  </div>
                </div>
                <Form name="basic" autoComplete="off" layout="vertical">
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Product Type"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="Product Type"
                        value={data?.ProductType}
                        onChange={(e) => {
                          setData({ ...data, ProductType: e });
                        }}
                        options={["Briquette", "Pellet"].map((item) => {
                          return { label: item, value: item };
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Raw Material"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="Raw Material"
                        value={data?.biomassProductType?.rawMaterial}
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              rawMaterial: e,
                            },
                          });
                        }}
                        options={[
                          "MUSTARD HUSK",
                          "GROUNDNUT SHELL",
                          "SAW DUST",
                        ].map((item) => {
                          return { label: item, value: item };
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Size (in mm)"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="Size (in mm)"
                        value={data?.biomassProductType?.size}
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              size: e,
                            },
                          });
                        }}
                        options={[
                          "40",
                          "50",
                          "70",
                          "80",
                          "90",
                          "100",
                          "110",
                        ].map((item) => {
                          return { label: item, value: item };
                        })}
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Minimum Order Quantity (in MT)"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Minimum Order Quantity"
                        value={data?.biomassProductType?.minimumOrderQty}
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              minimumOrderQty: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Packaging Type"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="Packaging Type"
                        value={data?.biomassProductType?.packagingType}
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              packagingType: e,
                            },
                          });
                        }}
                        options={["With Bags", "Without Bags", "Both"].map(
                          (item) => {
                            return { label: item, value: item };
                          }
                        )}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      width: "98%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "44px",
                    }}
                  >
                    <Text
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        fontWeight: "600",
                        fontSize: "16px",
                        width: "20%",
                      }}
                    >
                      Product Quality
                    </Text>
                    <div className={classes["divider-op"]}>
                      <Divider
                        style={{ marginTop: "12px", marginBottom: "0px" }}
                      />
                    </div>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="GCV (Cal/gm)"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="GCV (Cal/gm)"
                        value={
                          data?.biomassProductType?.productQuality
                            ?.grossCalorificValue
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              productQuality: {
                                ...data?.biomassProductType?.productQuality,
                                grossCalorificValue: e,
                              },
                            },
                          });
                        }}
                        options={[
                          "2000-2500",
                          "2500-3000",
                          "3000-3500",
                          "3500-4000",
                          "4000-4500",
                          ">4500",
                        ].map((item) => {
                          return { label: item, value: item };
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="ASH CONTENTS (%)"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="ASH CONTENTS (%)"
                        value={
                          data?.biomassProductType?.productQuality?.ashContent
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              productQuality: {
                                ...data?.biomassProductType?.productQuality,
                                ashContent: e,
                              },
                            },
                          });
                        }}
                        options={["3 TO 5", "6 TO 8", "9 TO 10", "> 10"].map(
                          (item) => {
                            return { label: item, value: item };
                          }
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="MOISTURE CONTENTS (%)"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="MOISTURE CONTENTS (%)"
                        value={
                          data?.biomassProductType?.productQuality
                            ?.moistureContent
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            biomassProductType: {
                              ...data?.biomassProductType,
                              productQuality: {
                                ...data?.biomassProductType?.productQuality,
                                moistureContent: e,
                              },
                            },
                          });
                        }}
                        options={["3 TO 5", "6 TO 8", "9 TO 10", "> 10"].map(
                          (item) => {
                            return { label: item, value: item };
                          }
                        )}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    style={{
                      marginTop: "20px",
                      marginBottom: "0px",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{ borderRadius: "4px" }}
                      onClick={onSaveProfile}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(AddProduct);

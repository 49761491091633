import React from "react";
import classes from "../../styles/login.module.css";
import { Button, Typography, Input, Form } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

const MobileLogin = () => {
  return (
    <div className={classes["mobile-container"]}>
      <div className={classes["top-left"]}></div>
      <div className={classes["bottom-right"]}></div>
      <div className={classes["mobile-content"]}>
        <div>
          <Text className={classes["welcome"]}>Welcome Back</Text>
        </div>
        <Form name="basic" autoComplete="off">
          <div style={{ marginTop: "32px" }}>
            <Text className={classes["enter"]}>Enter Mobile Number</Text>
          </div>
          <Form.Item style={{ marginTop: "16px" }}>
            <Input
              className={classes["input-box-mobile"]}
              placeholder="Enter Number"
              type="number"
            />
          </Form.Item>
          <Form.Item style={{ marginTop: "32px" }}>
            <Link to="/login">
              <Button
                type="primary"
                style={{ width: "90%" }}
                className={classes["mobile-login-button"]}
              >
                Get OTP
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default MobileLogin;

import { Button, Input, Modal, Radio, Space } from "antd";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const { TextArea } = Input;

const ReportAFraud = ({ open, setOpen }) => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <Modal
      title="Report a Fraud"
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => setOpen(false)}>
          Report
        </Button>,
      ]}
      centered
    >
      <Radio.Group
        onChange={onChange}
        value={value}
        style={{
          width: "100%",
        }}
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Radio value={1}>Option 1</Radio>
          <Radio value={2}>Option 2</Radio>
          <Radio value={3}>Option 3</Radio>
          <Radio value={4}>Others</Radio>
          {value === 4 && (
            <TextArea
              placeholder="Please describe the issue"
              autoSize={{ minRows: 2, maxRows: 6 }}
              style={{
                width: "100%",
              }}
            />
          )}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default ReportAFraud;

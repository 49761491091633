import React from "react";
import Navbar from "../Navbar/index";
import { Typography, Select, Button, Input, Space, message } from "antd";
import MobileSubscription from "./MobileSubscription";
import classes from "../../styles/subscription.module.css";
import Footer from "../Footer";
import axios from "axios";
import { useEffect } from "react";
import { marked } from "marked";
import { Link, useNavigate } from "react-router-dom";
import NotLogin from "../Modals/NotLogin";
import { connect } from "react-redux";

const { Text } = Typography;

const Subscription = ({ userDetails }) => {
  let navigate = useNavigate();
  const [value, setValue] = React.useState("manufacturer");
  const [plans, setPlans] = React.useState([]);
  const [loginModal, setLoginModal] = React.useState(false);
  useEffect(() => {
    fetchPlans();
  }, [value]);
  const fetchPlans = () => {
    let userType = value;
    if (userDetails?._id) {
      userType = userDetails?.role;
    }
    axios
      .get(`/plan?userType=${userType}`)
      .then((res) => {
        setPlans(res.data?.data);
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log(err);
      });
  };
  const subscribeUser = (planId) => {
    const request = {
      planid: planId,
    };
    axios
      .post(`/subscribe`, request)
      .then((res) => {
        navigate(`/checkout/${planId}/${res?.data?.order}`);
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again later");
      });
  };
  const getPlan = (id) => {
    if (!userDetails?._id) {
      setLoginModal(true);
    } else {
      subscribeUser(id);
    }
  };
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className={classes["desktop-container"]}>
        <div>
          {/* <div className={classes["desktop-header-container"]}>
            <div className={classes["desktop-header"]}>
              <div className={classes["desktop-header-left"]}>
                <div className={classes["desktop-header-left-content"]}>
                  <div style={{ marginTop: "-20px", marginRight: "10px" }}>
                    <Text className={classes["desktop-header-left-number"]}>
                      44
                    </Text>
                  </div>
                  <div>
                    <div>
                      <Text className={classes["desktop-header-left-text"]}>
                        Total
                      </Text>
                    </div>
                    <div>
                      <Text className={classes["desktop-header-left-text"]}>
                        Consumers in Roorkee
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes["desktop-header-right"]}>
                <div className={classes["desktop-header-select-div"]}>
                  <Select
                    className={classes["desktop-header-select"]}
                    defaultValue="Select Location"
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className={classes["desktop-content"]}>
            <div className={classes["header"]}>
              <div className={classes["plans"]}>Subscription Plans</div>
              {!userDetails?._id && (
                <div className={classes["type"]}>
                  <div
                    style={{
                      fontSize: "14px",
                      border: "1px solid #D9D9D9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      padding: "0px 20px",
                      background: value === "manufacturer" ? "#075C98" : "",
                      color: value === "manufacturer" ? "white" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setValue("manufacturer");
                    }}
                  >
                    Manufacturer
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      border: "1px solid #D9D9D9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      padding: "0px 20px",
                      background: value === "consumer" ? "#075C98" : "",
                      color: value === "consumer" ? "white" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setValue("consumer");
                    }}
                  >
                    Consumer
                  </div>
                </div>
              )}
            </div>
            <div className={classes["desktop-content-container"]}>
              {plans?.map((item) => {
                return (
                  <div className={classes["desktop-content-left"]}>
                    <div className={classes["desktop-content-top"]}>
                      {item?.isMostPopular === true ? (
                        <div className={classes["desktop-content-popular"]}>
                          <Text
                            style={{
                              color: "white",
                              marginLeft: "15px",
                              fontSize: "16px",
                              marginTop: "15px",
                            }}
                          >
                            Most Popular
                          </Text>
                        </div>
                      ) : null}
                      <div className={classes["desktop-content-top-container"]}>
                        <div className={classes["desktop-content-top-left"]}>
                          <div>
                            <Text
                              className={classes["desktop-content-top-state"]}
                            >
                              {item?.accessType}
                            </Text>
                          </div>
                          <div style={{ marginTop: "-10px" }}>
                            <Text
                              className={classes["desktop-content-top-prime"]}
                            >
                              {item?.type}
                            </Text>
                          </div>
                          <div
                            style={{
                              color: "#FFF",
                            }}
                          >
                            Paid Plan for {item?.duration} months
                          </div>
                        </div>
                        <div className={classes["desktop-content-top-right"]}>
                          <Text
                            className={classes["desktop-content-top-price"]}
                          >
                            ₹{item?.price}/-
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={classes["desktop-content-points-container"]}
                      >
                        <article
                          dangerouslySetInnerHTML={{
                            __html: marked(item?.description?.toString()),
                          }}
                        ></article>
                      </div>
                      <div className={classes["desktop-content-button-div"]}>
                        {/* <Link to={`/checkout/${item?._id}`}> */}
                        <Button
                          className={classes["desktop-content-button"]}
                          onClick={() => getPlan(item?._id)}
                        >
                          Subscribe Free
                        </Button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {loginModal && (
        <NotLogin open={loginModal} setOpen={setLoginModal} isCheckout={true} />
      )}
      {/* <div className={classes["mobile-container"]}>
        <MobileSubscription />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(Subscription);

import { takeLatest, fork } from "redux-saga/effects";
import ACTIONS from "./UserActionsType";
import { Store } from "../Config/Store";
import axios from "axios";
import { message } from "antd";

function* getUser(action) {
  const { dispatch } = Store;
  try {
    axios
      .get(`/user/currentUser`)
      .then((res) => {
        if (res.data.status === 401 || res.data.status === 400) {
          localStorage.removeItem("token");
          window.location.pathname = "/";
          return;
        }
        dispatch({
          type: ACTIONS.FETCH_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err?.response?.status);
        localStorage.removeItem("token");
        window.location.pathname = "/";
        return;

        // if (localStorage.getItem('token')) {
        //   localStorage.removeItem('token');
        //   window.location.pathname = '/';
        // }
        // dispatch({
        //   type: ACTIONS.FETCH_USER_FAIL,
        // });
      });
  } catch (error) {
    dispatch({
      type: ACTIONS.FETCH_USER_FAIL,
    });
    // console.log("get shop error: ", error);
  }
}

function* watchUsers() {
  yield takeLatest(ACTIONS.FETCH_USER_REQUEST, getUser);
}

// ACTION WATCHER
export default function* userSaga() {
  yield fork(watchUsers);
}

import React, { useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import classes from "./consumerList.module.css";
import { Button, Divider, Select, Radio, Rate, Tag, message } from "antd";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { connect } from "react-redux";
import { CheckOutlined, FilterOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { Direction } from "../../assets";
import NotLogin from "../Modals/NotLogin";
import ReportAFraud from "../Modals/reportAFraud";
import Filters from "../Modals/filters";
import {
  consumer_images,
  getDefaultImage,
  manufacturer_images,
} from "../../utils/constants";

const options = State.getStatesOfCountry("IN").map((state) => {
  return {
    value: state.isoCode,
    label: state.name,
  };
});

const ConsumerList = ({ userDetails }) => {
  let { role } = useParams();
  const [search, setSearch] = useState({
    state: null,
    city: null,
  });
  const [city, setCity] = useState([]);
  const [notLoginModal, setNotLoginModal] = useState(false);
  const [reportaFraud, setreportaFraud] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = React.useState({
    productType: undefined,
    size: undefined,
    rawMaterial: undefined,
    distance: 100,
  });
  const [data, setData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchResults = () => {
    if (!userDetails?._id) {
      setNotLoginModal(true);
      return;
    }
    if (!search?.state || !search?.city) {
      message.error("Please select state and city");
      return;
    }
    setLoading(true);
    axios
      .get(
        `/searchUsersByNear?state=${search?.state}&city=${
          search?.city
        }&productType=${filters?.productType}&size=${
          filters?.size
        }&rawMaterial=${filters?.rawMaterial}&distance=${
          filters?.distance * 1000
        }`
      )
      .then((res) => {
        setIsSearched(true);
        setLoading(false);
        console.log(res);
        setData(res?.data);
      });
  };
  return (
    <div>
      <Navbar />
      <div
        className={
          role === "consumer"
            ? classes["main-header-consumer"]
            : classes["main-header"]
        }
      >
        <div className={classes["main-header-text-container"]}>
          {role === "consumer" ? (
            <>
              <div className={classes["main-header-text"]}>
                Get a record of every consumer
                <br />
                present at a location
              </div>
              <div className={classes["main-header-text-sub"]}>
                Search by location to get details of all consumers at the
                location
              </div>
            </>
          ) : (
            <>
              <div className={classes["main-header-text"]}>
                Get a record of every manufacturer
                <br />
                present at a location
              </div>
              <div className={classes["main-header-text-sub"]}>
                Search by location and manufacturer name to get details of all
                manufacturers at the location
              </div>
            </>
          )}
        </div>
      </div>
      {/* Desktop Search Start */}
      <div className={classes["searching-container-desktop"]}>
        <form autoCapitalize="off">
          <div className={classes["searching"]}>
            <Select
              placeholder="Select State"
              showSearch
              bordered={false}
              size="large"
              style={{ width: "100%" }}
              value={search.state}
              autocomplete="nope"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={options}
              onChange={(value, op) => {
                console.log(value);
                setSearch({
                  state: op?.label,
                  city: null,
                });
                setCity(
                  City.getCitiesOfState("IN", value).map((city) => {
                    return {
                      value: city.name,
                      label: city.name,
                    };
                  })
                );
              }}
            />
            <Divider type="vertical" style={{ height: "3em" }} />
            <Select
              bordered={false}
              placeholder="Select City"
              value={search.city}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="large"
              autocomplete="nope"
              style={{
                width: "100%",
              }}
              options={city}
              onChange={(value) => {
                setSearch({
                  ...search,
                  city: value,
                });
              }}
            ></Select>
            <Divider type="vertical" style={{ height: "3em" }} />
            <Button
              size="large"
              type="primary"
              style={{
                width: "50%",
                backgroundColor: role === "consumer" ? "#075C98" : "#FD8C28",
                borderColor: role === "consumer" ? "#075C98" : "#FD8C28",
              }}
              loading={loading}
              onClick={searchResults}
            >
              Search
            </Button>
          </div>
        </form>
      </div>
      {/* Desktop Search End */}
      {/* Mobile Search Start */}
      <form autoCapitalize="off">
        <div className={classes["searching-container-mobile"]}>
          <div className={classes["searching-mobile"]}>
            <div
              style={{
                position: "relative",
                width: "100%",
                zIndex: "2",
              }}
            >
              <Select
                placeholder="Select State"
                showSearch
                bordered={false}
                size="large"
                style={{ width: "100%" }}
                value={search.state}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={options}
                onChange={(value, op) => {
                  console.log(value);
                  setSearch({
                    state: op?.label,
                    city: null,
                  });
                  setCity(
                    City.getCitiesOfState("IN", value).map((city) => {
                      return {
                        value: city.name,
                        label: city.name,
                      };
                    })
                  );
                }}
              />
            </div>
            <hr
              style={{
                width: "100%",
                color: "rgba(0, 0, 0, 0.16)",
                margin: "20px 0",
              }}
            />
            <Select
              bordered={false}
              placeholder="Select City"
              value={search.city}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="large"
              style={{
                width: "100%",
              }}
              options={city}
              onChange={(value) => {
                setSearch({
                  ...search,
                  city: value,
                });
              }}
            ></Select>
            <div
              style={{
                width: "100%",
                marginTop: "30px",
              }}
            >
              <Button
                size="large"
                type="primary"
                style={{
                  width: "100%",
                  backgroundColor: role === "consumer" ? "#075C98" : "#FD8C28",
                  borderColor: role === "consumer" ? "#075C98" : "#FD8C28",
                }}
                loading={loading}
                onClick={searchResults}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </form>
      {/* Mobile Search End */}
      {isSearched === true && (
        <div className={classes["main-container"]}>
          <div className={classes["searching-header"]}>
            <div className={classes["searhing-header-city"]}>
              <div className={classes["city-name"]}>{search?.city}</div>
              <div className={classes["detail"]}>{role} List</div>
            </div>
            <div className={classes["searhing-header-options"]}>
              <Button
                ghost
                style={{
                  color: "black",
                  borderColor: "#fff",
                  boxShadow: "none",
                }}
                size="large"
                onClick={() => setShowFilters(true)}
              >
                <FilterOutlined />
                Filters
              </Button>
              {/* <Radio.Group size="middle">
              <Radio.Button value="large">All</Radio.Button>
              <Radio.Button value="default">Requirement</Radio.Button>
              <Radio.Button value="small">Non-requirement</Radio.Button>
            </Radio.Group> */}
            </div>
          </div>
          <hr
            style={{
              color: "1px solid rgba(0, 0, 0, 0.16)",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            {data?.map((item, index) => (
              <div className={classes["search-box"]}>
                <div
                  style={
                    {
                      // width: "225px",
                      // height: "175px",
                    }
                  }
                >
                  <img
                    src={
                      item?.companyLogo
                        ? item?.companyLogo
                        : getDefaultImage(item?.role, index)
                    }
                    style={{
                      width: "175px",
                      height: "175px",
                    }}
                  ></img>
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {item?.name}
                  </div>
                  <div>
                    <Rate disabled defaultValue={Number(item?.totalrating)} />
                    <span
                      className="ant-rate-text"
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {Number(item?.totalrating)}/5
                    </span>
                    <Tag
                      color="#55acee"
                      icon={
                        <Direction
                          style={{
                            paddingTop: "7px",
                          }}
                        />
                      }
                    >
                      {item?.distance?.calculated.toFixed(2)} km
                    </Tag>
                    {item?.products?.length > 0 && role === "consumer" && (
                      <Tag color="#075C98">Requirement</Tag>
                    )}
                  </div>
                  <div
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      marginTop: "8px",
                    }}
                  >
                    Products :{" "}
                    {item?.products?.map(
                      (product) =>
                        `
                        ${product?.ProductType} (${product?.biomassProductType?.size}mm), `
                    )}
                  </div>
                  <div
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      marginTop: "8px",
                    }}
                  >
                    State : {item?.address?.state}
                  </div>
                  {item?.isUnlocked ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Link
                        to={`/details/${item?._id}`}
                        style={{
                          width: "100%",
                          marginTop: "12px",
                        }}
                      >
                        <Button
                          className={classes["desktop-content-button-success"]}
                          size="large"
                          style={{
                            width: "100%",
                          }}
                        >
                          <CheckOutlined />
                          Click here to View Details
                        </Button>
                      </Link>
                      {/* <Link
                        to={`/details/${item?._id}`}
                        style={{
                          width: "100%",
                          marginTop: "12px",
                        }}
                      >
                        <Button
                          className={classes["desktop-content-button"]}
                          size="large"
                          style={{
                            width: "100%",
                          }}
                          // onClick={() => setreportaFraud(true)}
                        >
                          View
                        </Button>
                      </Link> */}
                    </div>
                  ) : (
                    <Link to={`/details/${item?._id}`}>
                      <Button
                        className={classes["desktop-content-button"]}
                        size="large"
                        style={{
                          width: "100%",
                          marginTop: "12px",
                        }}
                      >
                        <LockOutlined />
                        Click here to Unlock & View Details
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Footer />
      {/* Modals */}
      {notLoginModal && (
        <NotLogin open={notLoginModal} setOpen={setNotLoginModal} />
      )}
      {reportaFraud && (
        <ReportAFraud open={reportaFraud} setOpen={setreportaFraud} />
      )}
      {showFilters && (
        <Filters
          open={showFilters}
          setOpen={setShowFilters}
          filters={filters}
          setFilters={setFilters}
          searchResults={searchResults}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(ConsumerList);

import { Button, Input, Modal, message } from "antd";
import React from "react";
import styles from "../NewPage/newPage.module.css";
import { LinkOutlined } from "@ant-design/icons";

const ShareModel = ({ open, setOpen, userId }) => {
  const link = `https://indhanbazaar.com/details/${userId}`;
  const copyLink = () => {
    navigator.clipboard.writeText(link);
    message.success("Link Copied to Clipboard");
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>,
      ]}
      centered
      title="Share"
    >
      <div className={styles.share_model}>
        <p>Copy Link</p>
        <Input.Group
          compact
          size="large"
          style={{
            width: "100%",
          }}
        >
          <Input
            prefix={<LinkOutlined />}
            size="large"
            style={{
              width: "calc(100% - 70px)",
            }}
            disabled
            defaultValue={link}
          />
          <Button size="large" type="primary" onClick={copyLink}>
            Copy
          </Button>
        </Input.Group>
      </div>
    </Modal>
  );
};

export default ShareModel;

import React, { useState } from "react";
import styles from "./newPage.module.css";
import {
  Button,
  Carousel,
  Col,
  Divider,
  Rate,
  Row,
  Space,
  Tabs,
  message,
} from "antd";
import { BonTon, Direction, Direction2, Review } from "../../assets";
import {
  CopyOutlined,
  HeartOutlined,
  HeartTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import ProductModal from "../Modals/productModal";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import UnlockUser from "../Modals/unlock";
import RateModel from "../Modals/rate";
import Confetti from "react-confetti";
import ShareModel from "../Modals/share";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import {
  consumer_images,
  getDefaultImage,
  manufacturer_images,
} from "../../utils/constants";

const Card1 = ({
  isUnlocked = false,
  userData = {},
  setUnlockUserModal,
  setRate,
  setShare,
  userDetails,
}) => {
  const [liked, setLiked] = useState(false);
  return (
    <div className={styles.card1}>
      <img
        src={
          userData?.companyLogo
            ? userData?.companyLogo
            : getDefaultImage(userDetails?.role)
        }
      />
      <div className={styles.card1Content}>
        <h2>{userData?.name}</h2>
        <Space align="center">
          <div className={styles.ratingNum}>
            {Number(userData?.totalrating)}
          </div>
          <Rate value={Number(userData?.totalrating)} disabled={true} />
          <p>{userData?.totalreview} ratings</p>
        </Space>
        <p>
          {userData?.address?.state} {userData?.address?.city}
        </p>
        <div className={styles.card1_button_container}>
          {!isUnlocked && (
            <button
              className={styles.cat1ButPrimary}
              onClick={() => setUnlockUserModal(true)}
            >
              Unlock Now
            </button>
          )}
          {isUnlocked && (
            <button className={styles.cat1But} onClick={() => setRate(true)}>
              Tap to Rate
            </button>
          )}
          <button className={styles.cat1But} onClick={() => setShare(true)}>
            Share
          </button>
          <button
            className={styles.cat1But}
            onClick={() => setLiked((prev) => !prev)}
          >
            {liked ? (
              <HeartTwoTone twoToneColor="#F93939" />
            ) : (
              <HeartOutlined />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const Card2 = ({ onClick, data = {}, setProductInfo }) => {
  const cardLabels1 = [
    "Raw Material",
    "Size (mm)",
    "GCV (Cal/gm)",
    "Ash Content (%)",
    "Moisture Content (%)",
    "Packing",
    "Min Order Qty.",
  ];

  const cardAns1 = [
    data?.biomassProductType?.rawMaterial,
    data?.biomassProductType?.size,
    data?.biomassProductType?.productQuality?.grossCalorificValue,
    data?.biomassProductType?.productQuality?.ashContent,
    data?.biomassProductType?.productQuality?.moistureContent,
    data?.biomassProductType?.packagingType,
    data?.biomassProductType?.minimumOrderQty,
  ];
  return (
    <div
      className={styles.card2}
      onClick={() => {
        setProductInfo(data);
        onClick();
      }}
    >
      <div className={styles.card2ImgCont}>
        <Carousel style={{ width: "100px" }} autoplay>
          {data?.photo?.map((photo) => {
            return (
              <div>
                <img src={photo} />
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className={styles.card2Content}>
        <Row gutter={[15, 0]} justify={"space-between"}>
          <Col>
            {cardLabels1.map((item) => {
              return <p>{item}</p>;
            })}
          </Col>
          <Col>
            {cardAns1.map((item) => {
              return <p>{item}</p>;
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const Card3 = () => {
  return (
    <div className={styles.card3}>
      <img src={BonTon} />
      <div className={styles.card3Content}>
        <h4>Lorem Ipsum Company Name</h4>
        <p>Roorkee, Uttarakhand</p>
        <Space>
          <h4>4.5</h4>
          <Rate value={1} />
        </Space>
      </div>
    </div>
  );
};

const ReviewCard = () => {
  return (
    <div className={styles.review}>
      <div className={styles.reviewHeader}>
        <Space>
          <img src={Review} className={styles.reviewGuy} />
          <div>
            <h3>Unknown</h3>
            <p>Roorkee, Uttrakhand</p>
          </div>
        </Space>
        <p>19th February 2023</p>
      </div>
      <Rate value={4} />
      <h5>
        Lorem ipsum dolor sit amet consectetur. Fames augue risus enim
        vestibulum turpis. Urna leo vivamus mi sit sed. Urna congue adipiscing
        sed pellentesque. Ullamcorper eu faucibus etiam sed malesuada ac id
        posuere tellus.
      </h5>
      <Space>
        <img />
        <p>Helpful (24)</p>
      </Space>
    </div>
  );
};

const RightSide = ({
  isUnlocked = false,
  setUnlockUserModal,
  userData = {},
  userDetails,
}) => {
  const companyDetails = [
    {
      label: "Mitra ID",
      value: userData?.mitraId,
    },
    {
      label: "GST No.",
      value: userData?.gstno,
    },
    {
      label: "Ownership Type",
      value: userData?.ownership,
    },
    {
      label: "No. of Employees",
      value: userData?.numberOfEmployees,
    },
    {
      label: "Year of Establishment",
      value: userData?.yearOfEstablishment,
    },
    {
      label: "Annual Turnover",
      value: userData?.annualTurnOver,
    },
    {
      label: "Payment Terms",
      value: userData?.paymentTerms,
    },
  ];
  if (userDetails?.role === "consumer") {
    companyDetails.push({
      label: "Raw Material Stock Capacity",
      value: userData?.rawMaterialStockCapacity,
    });
    companyDetails.push({
      label: "Finish Good Stock Capacity",
      value: userData?.finishGoodsStockCapacity,
    });
  } else {
    companyDetails.push({
      label: "Delivery Terms",
      value: userData?.deliveryTerms,
    });
    companyDetails.push({
      label: "Monthly Requirement",
      value: userData?.monthlyRequirement,
    });
  }

  const copyAddress = () => {
    navigator.clipboard.writeText(userData?.fullAddress);
    message.success("Address Copied");
  };

  return (
    <div className={styles.second_column}>
      {isUnlocked ? (
        <div className={styles.address}>
          <h5>Address:</h5>
          <p>{userData?.fullAddress}</p>
          <div className={styles.addressBtns}>
            <Button
              icon={<Icon component={Direction2} />}
              type="link"
              href={`https://www.google.com/maps/search/?api=1&query=${userData?.getDirections}`}
              target="_blank"
            >
              Get Direction
            </Button>
            <Button icon={<CopyOutlined />} onClick={copyAddress}>
              Copy
            </Button>
          </div>
          <Divider className={styles.divider} />
          <Button
            icon={<MailOutlined />}
            type="link"
            href={`mailto:${userData?.email}`}
          >
            {userData?.email}
          </Button>
          <Divider className={styles.divider} />
          <Button type="link" href={`tel:${userData?.phone}`}>
            +91 {userData?.phone}
          </Button>
        </div>
      ) : (
        <div className={styles.addressUnlocked}>
          <Button type="primary" onClick={() => setUnlockUserModal(true)}>
            Unlock Now
          </Button>
          <p>Click on Unlock Now to see more contact details.</p>
        </div>
      )}

      <div className={styles.companyDetails}>
        <h5> Company Details :</h5>
        <div className={styles.company_row_container}>
          {companyDetails.map((item) => {
            return (
              <div className={styles.company_row}>
                <div>{item.label}</div>
                <div>{item.value}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className={styles.desktop_view}>
        <div className={styles.moreCards}>
          <div className={styles.moreCardsHeader}>
            <h4>People also see: </h4>
            <h4>View All</h4>
          </div>
          <Card3 />
          <Card3 />
          <Card3 />
        </div>
      </div> */}
    </div>
  );
};

const NewPage = ({ userDetails }) => {
  let { id } = useParams();
  const [ratingValue, setRatingValue] = useState({ 0: 4, 1: 5 });
  const [tab, setTab] = useState("briquette");
  const [productInfo, setProductInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [unlockUserModal, setUnlockUserModal] = React.useState(false);
  const [rate, setRate] = React.useState(false);
  const [share, setShare] = React.useState(false);
  const [isConfetti, setIsConfetti] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (isConfetti) {
      setTimeout(() => {
        setIsConfetti(false);
      }, 2500);
    }
  }, [isConfetti]);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    setLoading(true);
    axios
      .get(`/user/getUserForCard/${id}`)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        if (res?.data?.isUnlocked === true) {
          setIsUnlocked(true);
        }
        // seperate the products with ProductType as briquette and pellets
        let briquette = [];
        let pellets = [];
        res?.data?.data?.products?.forEach((item) => {
          if (item?.ProductType === "Briquette") {
            briquette.push(item);
          } else {
            pellets.push(item);
          }
        });
        setUserData({
          ...res?.data?.data,
          briquette,
          pellets,
        });
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || "Something went wrong");
        setLoading(false);
        console.log(err);
      });
  };

  const unlockUserRequest = () => {
    const request = {
      unlockUserId: id,
    };
    axios
      .post("/user/unlock", request)
      .then((res) => {
        setIsUnlocked(true);
        setIsConfetti(true);
        setUnlockUserModal(false);
        setUserData({
          ...userData,
          ...res?.data,
        });
        // fetchUser();
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        message.error(err?.response?.data?.message);
        console.log(err);
      });
  };

  const items = [
    {
      label: "Briquette",
      key: "briquette",
    },
    {
      label: "Pellets",
      key: "pellets",
    },
  ];

  const cardOnClick = () => {
    setOpen(true);
  };

  if (loading) {
    return <Loader />;
  }

  console.log(tab, userData);

  return (
    <div>
      <Navbar />
      {isConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={600}
          recycle={false}
        />
      )}
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.first_column}>
            <Card1
              ratingValue={ratingValue}
              mark={"0"}
              totalRatingsCount="1000"
              isUnlocked={isUnlocked}
              userData={userData}
              setUnlockUserModal={setUnlockUserModal}
              setRate={setRate}
              setShare={setShare}
              userDetails={userDetails}
            />
            {/* <div className={styles.mobile_view}>
              <RightSide />
            </div> */}
            <div className={styles.products}>
              <h3>Products</h3>
              <Tabs
                // tabBarExtraContent={operations}
                items={items}
                onChange={(e) => setTab(e)}
              />
              <div className={styles.products_cont}>
                <div className={styles.productCardsContent}>
                  {tab === "briquette" &&
                    userData?.briquette?.map((item) => {
                      return (
                        <Card2
                          onClick={cardOnClick}
                          setProductInfo={setProductInfo}
                          data={item}
                        />
                      );
                    })}
                  {tab === "pellets" &&
                    userData?.pellets?.map((item) => {
                      return (
                        <Card2
                          onClick={cardOnClick}
                          setProductInfo={setProductInfo}
                          data={item}
                        />
                      );
                    })}
                </div>
                {/* <button className={styles.seeMore}>See More</button> */}
                {/* <div className={styles.reviews}>
                  <h4>User Reviews: </h4>
                  <div>
                    <ReviewCard />
                    <Divider />
                    <ReviewCard />
                    <Divider />
                    <ReviewCard />
                    <Divider />
                    <ReviewCard />
                  </div>
                  <button>See all reviews</button>
                </div> */}
              </div>
            </div>
          </div>
          <RightSide
            isUnlocked={isUnlocked}
            setUnlockUserModal={setUnlockUserModal}
            userData={userData}
            userDetails={userDetails}
          />
        </div>
      </div>
      <ProductModal open={open} setOpen={setOpen} productInfo={productInfo} />
      <UnlockUser
        open={unlockUserModal}
        setOpen={setUnlockUserModal}
        setIsUnlocked={setIsUnlocked}
        setIsConfetti={setIsConfetti}
        unlockUserRequest={unlockUserRequest}
      />
      <RateModel open={rate} setOpen={setRate} />
      <ShareModel open={share} setOpen={setShare} userId={id} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(NewPage);

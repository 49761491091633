import { useState } from "react";
import Navbar from "../Navbar/index";
import classes from "../../styles/product.module.css";
import {
  Typography,
  Menu,
  Button,
  Table,
  Empty,
  Popconfirm,
  message,
} from "antd";
import MobileProductList from "./MobileProductList";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Footer from "../Footer";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Text = Typography;
const items = [
  {
    label: "Briquette",
    key: "Briquette",
  },
  {
    label: "Pellet",
    key: "Pellet",
  },
];
const ProductList = ({ userDetails }) => {
  const [current, setCurrent] = useState("Briquette");
  const [products, setProducts] = useState([]);
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  useEffect(() => {
    fetchProducts();
  }, [current]);

  const fetchProducts = async () => {
    axios
      .get(`/product/list?type=${current}`)
      .then((res) => {
        setProducts(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteProduct = (id) => {
    axios
      .post("product/delete", { id })
      .then((res) => {
        message.success("Product Deleted Successfully");
        fetchProducts();
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log(err);
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <div>
        <Navbar />
      </div>
      <div className={classes["desktop-container"]}>
        <div className={classes["desktop-header"]}>
          <Text className={classes["desktop-header-text"]}>
            {userDetails?.role === "consumer"
              ? "Requirement List"
              : "Product List"}
          </Text>
          <div>
            <Link to="/product/add">
              <Button type="primary" className={classes["desktop-button-add"]}>
                {userDetails?.role === "consumer"
                  ? "Add New Requirement"
                  : "Add New Product"}
              </Button>
            </Link>
          </div>
        </div>
        <div className={classes["desktop-topbar"]}>
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
            style={{ marginLeft: "-20px", paddingLeft: "0px", width: "100%" }}
          />
          {/* <Button
            ghost
            style={{
              color: "black",
              borderColor: "#fff",
              boxShadow: "none",
            }}
          >
            <FilterOutlined />
            Filter
          </Button> */}
        </div>
        <div className={classes["product_card_container"]}>
          {products.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
              }}
            >
              <Empty />
            </div>
          )}
          {products.map((item) => {
            const displayDetails = [
              {
                name: "Raw Material",
                value: item?.biomassProductType?.rawMaterial,
              },
              {
                name: "Size",
                value: item?.biomassProductType?.size,
              },
              {
                name: "GCV (Cal/gm)",
                value:
                  item?.biomassProductType?.productQuality?.grossCalorificValue,
              },
              {
                name: "Ash Content (%)",
                value: item?.biomassProductType?.productQuality?.ashContent,
              },
              {
                name: "Moisture Content (%)",
                value:
                  item?.biomassProductType?.productQuality?.moistureContent,
              },
              {
                name: "Packaging Type",
                value: item?.biomassProductType?.packagingType,
              },
            ];
            return (
              // <Link
              //   key={item?._id}
              //   to={`/product/add?id=${item?._id}`}
              //   style={{
              //     textDecoration: "none",
              //     color: "inherit",
              //     width: ;
              //   }}
              // >
              <div key={item?.number} className={classes["product_card"]}>
                <img
                  src={
                    item?.photo?.length > 0
                      ? item?.photo[0]
                      : "https://cdn.indhanbazaar.com/webassets/d8763ae87c7f69c56082.png"
                  }
                  height="160px"
                  width="160px"
                />
                <div className={classes["product_card_content_container"]}>
                  {displayDetails.map((display) => (
                    <div className={classes["product_card_content"]}>
                      <div className={classes["product_card_content_width"]}>
                        {display?.name}
                      </div>
                      <div className={classes["product_card_content_width"]}>
                        {display?.value}
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                      padding: "5px 10px",
                    }}
                  >
                    <Link
                      style={{
                        width: "100%",
                      }}
                      to={`/product/add?id=${item?._id}`}
                    >
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                        }}
                      >
                        <EditOutlined />
                        Edit
                      </Button>
                    </Link>
                    <Popconfirm
                      title="Delete this product?"
                      description="Are you sure to delete this product?"
                      onConfirm={() => deleteProduct(item?._id)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{
                          width: "100%",
                        }}
                        danger
                      >
                        <DeleteOutlined />
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
              // </Link>
            );
          })}
        </div>
        {/* <div className={classes["desktop-table"]}>
          <Table
            columns={columns}
            dataSource={data}
            size="small"
            scroll={{
              x: "100%",
              //   y: "50vh",
            }}
          />
        </div> */}
      </div>
      <Footer />
      {/* <div className={classes["mobile-container"]}>
        <MobileProductList />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(ProductList);

import { useState } from "react";
import classes from "../../styles/profile.module.css";
import {
  Button,
  Typography,
  Input,
  Upload,
  Form,
  InputNumber,
  Select,
  Divider,
} from "antd";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { MobileUser } from "../../assets";
const { Text } = Typography;
const options = [
  {
    value: "jack",
    label: "Jack",
  },
  {
    value: "lucy",
    label: "Lucy",
  },
  {
    value: "Yiminghe",
    label: "yiminghe",
  },
];
const MobileProfile = () => {
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  const uploadButton = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div>
          <MobileUser />
        </div>
      )}
    </div>
  );
  return (
    <div className={classes["mobile-container"]}>
      <div className={classes["top-left"]}></div>
      <div className={classes["bottom-right"]}></div>

      <div className={classes["mobile-content"]}>
        <div style={{ marginBottom: "16px" }}>
          <Text className={classes["mobile-left-side-main-Text"]}>
            Edit Profile
          </Text>
        </div>
        <div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/" style={{ fontSize: "14px" }}>
            Upload Company Logo
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          <Text
            style={{
              color: "rgba(0, 0, 0, 0.6)",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            Company Details
          </Text>
          <div style={{ width: "65%" }}>
            <Divider style={{ marginTop: "12px", marginBottom: "0px" }} />
          </div>
        </div>
        <Form name="basic" autoComplete="off">
          <Form.Item style={{ marginTop: "15px", marginBottom: "0px" }}>
            <Input
              className={classes["input-box"]}
              placeholder="Company Name"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="User Name" />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Select
              className={classes["select-box-inside"]}
              defaultValue="Select State"
              options={options}
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <InputNumber
              className={classes["input-box"]}
              addonBefore="+91"
              placeholder="contact"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="Email Id" />
          </Form.Item>

          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="GST No" />
          </Form.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <Text
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Address
            </Text>
            <div style={{ width: "80%" }}>
              <Divider style={{ marginTop: "12px", marginBottom: "0px" }} />
            </div>
          </div>
          <Form.Item className={classes["form-margin"]}>
            <Select
              className={classes["select-box-inside"]}
              defaultValue="Select State"
              options={options}
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Select
              className={classes["select-box-inside"]}
              defaultValue="Select State"
              options={options}
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="Town" />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="Landmark" />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="Street Name" />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="Plot No" />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input className={classes["input-box"]} placeholder="Pin Code" />
          </Form.Item>

          <Form.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Link to="/login">
              <Button type="primary" style={{ width: "100%" }}>
                Save
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default MobileProfile;

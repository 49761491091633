import React from "react";
import styles from "./Content.module.css";
import { marked } from "marked";

const Content = ({ data }) => {
  return (
    <div className={styles.content_text}>
      <article
        dangerouslySetInnerHTML={{
          __html: marked(data),
        }}
      ></article>
    </div>
  );
};

export default Content;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/index";
import { Button, Typography, Input, Form, message } from "antd";
import classes from "../../styles/login.module.css";
import MobileLogin from "./MobileLogin";
import axios from "axios";
const { Text } = Typography;
const Login = () => {
  let navigate = useNavigate();

  const [phone, setPhone] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const login = () => {
    if (phone.length !== 10) {
      message.error("Please enter a valid phone number");
      return;
    }
    setLoading(true);
    axios
      .post("/user/login", {
        phone: phone,
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        navigate(`/otp/${res.data.token}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Something went wrong");
      });
  };

  const enterLogin = (event) => {
    if (event.keyCode === 13) {
      login();
    }
  };

  return (
    <div>
      <div className={classes["main-container"]}>
        <Navbar login={true} />
        <div className={classes["container"]}>
          <div className={classes["left-side"]}></div>
          <div className={classes["top-left"]}></div>
          <div className={classes["bottom-right"]}></div>
          <div className={classes["right-side"]}>
            <div>
              <Text className={classes["welcome"]}>Login</Text>
            </div>
            <Form name="basic" autoComplete="off">
              <div style={{ marginTop: "32px" }}>
                <Text className={classes["enter"]}>Enter Mobile Number</Text>
              </div>
              <Form.Item style={{ marginTop: "16px" }}>
                <Input
                  className={classes["input-box"]}
                  placeholder="Enter Number"
                  style={{ width: "100%" }}
                  addonBefore="+91"
                  type="number"
                  size="large"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  onKeyDown={(e) => enterLogin(e)}
                />
              </Form.Item>
              <Form.Item style={{ marginTop: "32px" }}>
                {/* <Link to="/otp"> */}
                <Button
                  type="primary"
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  onClick={login}
                  loading={loading}
                  disabled={loading}
                >
                  Get OTP
                </Button>
                {/* </Link> */}
              </Form.Item>
            </Form>
            <Text className={classes["enter"]}>
              Don't have an account? <Link to="/signup">Signup</Link>
            </Text>
          </div>
        </div>
        {/* <div className={classes["login-mobile"]}>
          <MobileLogin />
        </div> */}
      </div>
    </div>
  );
};

export default Login;

import axios from 'axios';

export default function setAuthToken(token) {
  axios.defaults.headers.common['x-access-token'] = '';
  delete axios.defaults.headers.common['x-access-token'];

  if (token) {
    axios.defaults.headers.common['x-access-token'] = `${token}`;
  }
}

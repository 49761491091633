import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo, DownArrow, UpArrow } from "../../assets";
import classes from "../../styles/navbar.module.css";
import {
  CloseOutlined,
  DiffOutlined,
  LogoutOutlined,
  CopyOutlined,
  EditOutlined,
  UserOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Button, Space, Typography, Avatar } from "antd";
import { connect } from "react-redux";
const { Text } = Typography;

const MobileNavbarMenu = ({ setOpenModal, openModal, userDetails }) => {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const handleChange = () => {
    setOpenModal(false);
    console.log("clicked");
  };
  const truncateText = (str = "") => {
    return str?.length > 10 ? str?.substring(0, 10) + "..." : str;
  };
  const loginButton = (type) => {
    switch (type) {
      case "login":
        navigate("/login");
        break;
      case "signup":
        navigate("/signup");
        break;
      default:
        navigate("/login");
        break;
    }
  };
  return (
    <div className={classes["mobile-content-navbar"]}>
      {/* <div
        className={classes["navbar-container"]}
        style={{ backgroundColor: "white" }}
      >
        <Link to="/" style={{ zIndex: 1 }}>
          <Logo />
        </Link>
        <div className={classes["menu-icon"]}>
          <CloseOutlined onClick={handleChange} />
        </div>
      </div> */}
      <div className={classes["navbar-options"]}>
        <Link to="/" className={classes["navbar-text"]}>
          Home
        </Link>
        {!userDetails?.role && (
          <>
            <Link to="/search/consumer" className={classes["navbar-text"]}>
              Consumer
            </Link>
            <Link to="/search/manufacturer" className={classes["navbar-text"]}>
              Manufacturer
            </Link>
          </>
        )}
        {userDetails?.role === "manufacturer" && (
          <Link to="/search/consumer" className={classes["navbar-text"]}>
            Search Consumer
          </Link>
        )}
        {userDetails?.role === "consumer" && (
          <Link to="/search/manufacturer" className={classes["navbar-text"]}>
            Search Manufacturer
          </Link>
        )}
        {userDetails?.isSub === true ? (
          <Link to="/product/list" className={classes["navbar-text"]}>
            {userDetails?.role === "consumer"
              ? "Requirement List"
              : "Product List"}
          </Link>
        ) : (
          <Link to="/subscription" className={classes["navbar-text"]}>
            Pricing
          </Link>
        )}
        {!userDetails?._id && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
              marginTop: "30vh",
            }}
          >
            <Button
              className={classes["login-signup-button"]}
              onClick={() => loginButton("login")}
            >
              Log In
            </Button>
            <Button
              className={classes["login-signup-button"]}
              onClick={() => loginButton("signup")}
            >
              Sign Up
            </Button>
          </div>
        )}
        {userDetails?._id && (
          <div style={{ marginTop: "20px" }}>
            <Space
              style={{
                cursor: "pointer",
              }}
            >
              <div>
                {/* <Profile width={"40px"} height={"40px"} /> */}
                <Avatar
                  width={"40px"}
                  height={"40px"}
                  icon={<UserOutlined />}
                />
              </div>
              <Text
                style={{
                  color: "white",
                  marginLeft: "10px",
                  fontSize: "16px",
                }}
                onClick={() => setCollapse(!collapse)}
              >
                {truncateText(userDetails?.ownername)}
              </Text>
              {!collapse && (
                <DownArrow
                  width={"10px"}
                  height={"10px"}
                  style={{ marginLeft: "10px" }}
                  onClick={() => setCollapse(!collapse)}
                />
              )}
              {collapse && (
                <UpArrow
                  width={"20px"}
                  height={"20px"}
                  onClick={() => setCollapse(!collapse)}
                />
              )}
            </Space>
            {collapse && (
              <div>
                <div className={classes["collapse-content"]}>
                  <Link to="/profile">
                    <EditOutlined className={classes["collapse-icon"]} />
                    <Text className={classes["collapse-text"]}>
                      Edit Profile
                    </Text>
                  </Link>
                </div>
                <div className={classes["collapse-content"]}>
                  <Link to="/product/add">
                    <CopyOutlined className={classes["collapse-icon"]} />
                    <Text className={classes["collapse-text"]}>
                      {userDetails?.role === "consumer"
                        ? "Add New Requirement"
                        : "List New Product"}
                    </Text>
                  </Link>
                </div>
                <div className={classes["collapse-content"]}>
                  <Link to="/product/list">
                    <DiffOutlined className={classes["collapse-icon"]} />
                    <Text className={classes["collapse-text"]}>
                      {userDetails?.role === "consumer"
                        ? "Requirement List"
                        : "Product List"}
                    </Text>
                  </Link>
                </div>
                <div className={classes["collapse-content"]}>
                  <Link to="/subscription_history">
                    <HistoryOutlined className={classes["collapse-icon"]} />
                    <Text className={classes["collapse-text"]}>
                      Subscription History
                    </Text>
                  </Link>
                </div>
                <div className={classes["collapse-content"]}>
                  <LogoutOutlined className={classes["collapse-icon"]} />
                  <Text className={classes["collapse-text"]}>Log Out</Text>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(MobileNavbarMenu);

import { Button, Modal } from "antd";
import { Link } from "react-router-dom";

const SubscriptionModal = ({ open, setOpen }) => {
  return (
    <Modal open={open} onCancel={() => setOpen(false)} footer={null} centered>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          <div>Subscribe for Free!</div>
        </div>
        <Link to="/subscription">
          <Button
            type="primary"
            style={{
              marginTop: "20px",
            }}
          >
            Subscribe Free
          </Button>
        </Link>
      </div>
    </Modal>
  );
};
export default SubscriptionModal;

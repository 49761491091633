import { useEffect, useState } from "react";
import Navbar from "../Navbar/index";
import classes from "../../styles/profile.module.css";
import {
  Typography,
  Upload,
  Input,
  Divider,
  Form,
  Select,
  InputNumber,
  Button,
  message,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MobileProfile from "./MobileProfile";
import { Avatar, UserDesktop } from "../../assets";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
const Text = Typography;

const deliveryTerms = [
  "1 Day",
  "2 Days",
  "3 Days",
  "4 Days",
  "5 Days",
  "3-5 Days",
];

const Profile = ({ userDetails }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetchUser();
  }, []);
  const fetchUser = () => {
    axios
      .get("/user/getUser")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };
  const ownership = ["Proprietorship", "Partnership", "Private Limited"];
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  // Save Details
  const saveDetails = () => {
    console.log(data);
    // data.companyLogo = imageUrl;
    delete data?.isActive;
    axios
      .post(`/user/update/`, {
        userBody: data,
      })
      .then(() => {
        fetchUser();
        message.success("Lab Information Updated Succesfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Error Updating The Lab Information");
      });
  };
  // End Save Details

  // Profile Photo Upload
  const onSaveProfile = (imageUrl) => {
    console.log(data);
    data.companyLogo = imageUrl;
    delete data?.isActive;
    axios
      .post(`/user/update/`, {
        userBody: data,
      })
      .then(() => {
        fetchUser();
        message.success("Lab Information Updated Succesfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Error Updating The Lab Information");
      });
  };
  const upload = async (e) => {
    const file = e.target.files[0];
    const fileType = file?.type?.split("/")[1];
    const imageName = data?._id;
    const folderName = "company";
    const url = await axios.get(
      `/profiles3Url?fileType=${fileType}&imageName=${imageName}&folderName=${folderName}`
    );
    // const { data } = url;
    await fetch(url?.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": file?.type,
      },
      body: file,
    });
    const imageUrl = `https://indhanbazaar1.s3.ap-south-1.amazonaws.com/${folderName}/${imageName}.${fileType}`;
    onSaveProfile(imageUrl);
  };
  // End Profile Photo Upload

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className={classes["desktop-container"]}>
        <div className={classes["top-left"]}></div>
        <div className={classes["bottom-right"]}></div>
        <div className={classes["desktop-content"]}>
          <div className={classes["desktop-left-side"]}>
            <div className={classes["desktop-left-side-content"]}>
              <div style={{ marginBottom: "28px" }}>
                <Text className={classes["desktop-left-side-main-Text"]}>
                  Profile
                </Text>
              </div>
              <div>
                {data?.companyLogo ? (
                  <img
                    src={data?.companyLogo}
                    style={{
                      width: "116px",
                      height: "116px",
                    }}
                  ></img>
                ) : (
                  <Avatar />
                )}
                {/* <UserDesktop /> */}
                {/* <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload> */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "16px",
                }}
              >
                <Input
                  type="file"
                  style={{
                    width: "60%",
                  }}
                  accept="image/png, image/jpeg"
                  onChange={(e) => upload(e)}
                />
              </div>
            </div>
          </div>
          <div>
            <Divider type="vertical" style={{ height: "100%" }} />
          </div>
          <div className={classes["desktop-right-side"]}>
            <div>
              <div>
                <div
                  style={{
                    width: "98%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Company Details
                  </Text>
                  {/* <Form.Item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "sticky",
                    }}
                  > */}
                  <Button
                    type="primary"
                    style={{ borderRadius: "4px" }}
                    onClick={saveDetails}
                  >
                    Save Details
                  </Button>
                  {/* </Form.Item> */}
                </div>
                <Form name="basic" autoComplete="off" layout="vertical">
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Company Name"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Company Name"
                        value={data.name}
                        disabled
                        onChange={(e) => {
                          setData({ ...data, name: e.target.value });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Owner Name"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Owner Name"
                        value={data.ownername}
                        disabled
                        onChange={(e) => {
                          setData({ ...data, ownername: e.target.value });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Ownership Type"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        defaultValue="Ownership Type"
                        value={data.ownership}
                        disabled
                        onChange={(e) => {
                          setData({ ...data, ownership: e });
                        }}
                        options={ownership?.map((item) => {
                          return { value: item, label: item };
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="GST Number"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="GST Number"
                        value={data.gstno}
                        disabled
                        onChange={(e) => {
                          setData({ ...data, gstno: e.target.value });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Phone"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        addonBefore="+91"
                        placeholder="Phone"
                        type="number"
                        disabled
                        value={data.phone}
                        onChange={(e) => {
                          setData({ ...data, phone: e.target.value });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Email Id"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Email Id"
                        value={data.email}
                        disabled
                        onChange={(e) => {
                          setData({ ...data, email: e.target.value });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Number of Employees"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Number of Employees"
                        value={data.numberOfEmployees}
                        type="number"
                        onChange={(e) => {
                          setData({
                            ...data,
                            numberOfEmployees: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Year of Establishment"
                    >
                      <DatePicker
                        picker="year"
                        className={classes["desktop-right-side-input"]}
                        placeholder="Year of Establishment"
                        value={moment(data.yearOfEstablishment || new Date())}
                        onChange={(date, dateString) => {
                          setData({
                            ...data,
                            yearOfEstablishment: dateString,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Annual Turnover"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Annual Turnover"
                        value={data.annualTurnOver}
                        onChange={(e) => {
                          setData({
                            ...data,
                            annualTurnOver: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Payment Terms"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Payment Terms"
                        value={data.paymentTerms}
                        onChange={(e) => {
                          setData({
                            ...data,
                            paymentTerms: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  {userDetails?.role === "consumer" && (
                    <div className={classes["desktop-right-side-row"]}>
                      <Form.Item
                        className={classes["desktop-right-side-formItem"]}
                        label="Delivery Terms"
                      >
                        <Select
                          className={classes["desktop-right-side-select"]}
                          placeholder="Delivery Terms"
                          value={data.deliveryTerms}
                          onChange={(e) => {
                            setData({ ...data, deliveryTerms: e });
                          }}
                          options={deliveryTerms?.map((item) => {
                            return { value: item, label: item };
                          })}
                        />
                      </Form.Item>
                      <Form.Item
                        className={classes["desktop-right-side-formItem"]}
                        label="Monthly Requirement"
                      >
                        <Input
                          className={classes["desktop-right-side-input"]}
                          placeholder="Monthly Requirement"
                          value={data.monthlyRequirement}
                          onChange={(e) => {
                            setData({
                              ...data,
                              monthlyRequirement: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                  )}
                  {userDetails?.role === "manufacturer" && (
                    <div className={classes["desktop-right-side-row"]}>
                      <Form.Item
                        className={classes["desktop-right-side-formItem"]}
                        label="Raw Material Stock Capacity (in MT)"
                      >
                        <Input
                          className={classes["desktop-right-side-input"]}
                          placeholder="Raw Material Stock Capacity"
                          value={data.rawMaterialStockCapacity}
                          onChange={(e) => {
                            setData({
                              ...data,
                              rawMaterialStockCapacity: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        className={classes["desktop-right-side-formItem"]}
                        label="Finish Goods Stock Capacity (in MT)"
                      >
                        <Input
                          className={classes["desktop-right-side-input"]}
                          placeholder="Finish Goods Stock Capacity"
                          value={data.finishGoodsStockCapacity}
                          onChange={(e) => {
                            setData({
                              ...data,
                              finishGoodsStockCapacity: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div
                    style={{
                      width: "98%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "44px",
                    }}
                  >
                    <Text
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      Address
                    </Text>
                    <div style={{ width: "91%" }}>
                      <Divider
                        style={{ marginTop: "12px", marginBottom: "0px" }}
                      />
                    </div>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="State"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        defaultValue={data?.address?.state}
                        value={data?.address?.state}
                        // options={options}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="City"
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        defaultValue={data?.address?.city}
                        value={data?.address?.city}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Town"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Town"
                        value={data?.address?.town}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Landmark"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Landmark"
                        value={data?.address?.landmark}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className={classes["desktop-right-side-row"]}>
                    <Form.Item
                      className={classes["desktop-right-side-formItem"]}
                      label="Street Name"
                    >
                      <Input
                        className={classes["desktop-right-side-input"]}
                        placeholder="Street Name"
                        value={data?.address?.streetname}
                        disabled
                      />
                    </Form.Item>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        className={classes["desktop-right-side-formItem"]}
                        label="Plot No"
                      >
                        <Input
                          className={classes["desktop-right-side-input-two"]}
                          placeholder="Plot No"
                          disabled
                          value={data?.address?.plotno}
                        />
                      </Form.Item>
                      <Form.Item
                        className={classes["desktop-right-side-formItem"]}
                        label="Pin Code"
                      >
                        <Input
                          className={classes["desktop-right-side-input-two"]}
                          placeholder="Pin Code"
                          value={data?.address?.pincode}
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classes["mobile-container"]}>
        <MobileProfile />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(Profile);

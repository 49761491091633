import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/index";
import {
  Button,
  Typography,
  Input,
  Radio,
  Form,
  InputNumber,
  Select,
  message,
  Modal,
} from "antd";
import classes from "../../styles/signup.module.css";
import MobileSignup from "./MobileSignup";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { Checkmark } from "react-checkmark";

const plainOptions = ["manufacturer", "consumer"];
const options = State.getStatesOfCountry("IN").map((state) => {
  return {
    value: state.isoCode,
    label: state.name,
  };
});
const ownership = ["Proprietorship", "Partnership", "Private Limited"];
const { Text } = Typography;
const Signup = () => {
  let navigate = useNavigate();

  // const [value, setValue] = useState("Manufacturer");
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    role: "manufacturer",
  });
  const [city, setCity] = useState([]);

  const onChange = ({ target: { value } }) => {
    setData({ ...data, role: value });
  };

  const validate = () => {
    if (data?.phone?.length !== 10) {
      message.error("Phone number should be 10 digits");
      return false;
    }
    if (data?.gstno?.length !== 15) {
      message.error("GST number should be 15 digits");
      return false;
    }
    return true;
  };

  const submit = () => {
    console.log(data);
    if (!validate()) {
      return;
    }
    let rep = { ...data };
    // rep["address"] = [rep.address];
    setLoading(true);
    axios
      .post("/user/create", rep)
      .then((res) => {
        setLoading(false);
        console.log(res);
        message.success("User Created Successfully");
        setSignupSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 403) {
          message.error("User already exists");
          return;
        }
        console.log(err);
        message.error("Something went wrong");
      });
  };

  const login = () => {
    axios
      .post("/user/login", {
        phone: data.phone,
      })
      .then((res) => {
        console.log(res);
        navigate(`/otp/${res.data.token}?page=signup`);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  return (
    <div>
      <div className={classes["main-container"]}>
        <Navbar login={true} />
        <div className={classes["container"]}>
          <div className={classes["left-side"]}></div>
          <div className={classes["top-left"]}></div>
          <div className={classes["bottom-right"]}></div>
          <div className={classes["right-side"]}>
            <div className={classes["right-side-container"]}>
              <div>
                <Text className={classes["welcome"]}>Sign Up</Text>
              </div>
              <Form name="basic" autoComplete="off">
                <Form.Item
                  label="User Type"
                  name="role"
                  className={classes["form-margin"]}
                >
                  <Radio.Group
                    options={plainOptions}
                    style={{
                      textTransform: "capitalize",
                    }}
                    onChange={onChange}
                    defaultValue={data?.role}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginTop: "28px", marginBottom: "0px" }}
                  name="ownername"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input
                    className={classes["input-box"]}
                    placeholder="Name"
                    onChange={(e) =>
                      setData({ ...data, ownername: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  className={classes["form-margin"]}
                  name="name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input
                    className={classes["input-box"]}
                    placeholder="Company Name"
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
                <div style={{ marginTop: "17px" }}>
                  <div className={classes["two-input"]}>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="email"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        className={classes["input-box"]}
                        placeholder="Email"
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="Ownership Type"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Select
                        className={classes["desktop-right-side-select"]}
                        placeholder="Ownership Type"
                        // defaultValue="Ownership Type"
                        // value={data.ownership}
                        onChange={(e) => {
                          setData({ ...data, ownership: e });
                        }}
                        options={ownership?.map((item) => {
                          return { value: item, label: item };
                        })}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div style={{ marginTop: "17px" }}>
                  <div className={classes["two-input"]}>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="phone"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        addonBefore="+91"
                        placeholder="Contact"
                        type="number"
                        onChange={(e) =>
                          setData({ ...data, phone: e.target.value })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="gstno"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        placeholder="GST No."
                        onChange={(e) =>
                          setData({ ...data, gstno: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className={classes["form-margin"]}>
                  <div className={classes["two-input"]}>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="plotno"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        placeholder="Plot No"
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: {
                              ...data?.address,
                              plotno: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="streetname"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        className={classes["input-box"]}
                        placeholder="Street Name"
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: {
                              ...data?.address,
                              streetname: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  className={classes["form-margin"]}
                  name="landmark"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input
                    className={classes["input-box"]}
                    placeholder="Landmark"
                    onChange={(e) =>
                      setData({
                        ...data,
                        address: {
                          ...data?.address,
                          landmark: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>

                <div className={classes["form-margin"]}>
                  <div className={classes["two-input"]}>
                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="town"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        className={classes["input-box"]}
                        placeholder="Town"
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: {
                              ...data?.address,
                              town: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      className={classes["input-box-inside"]}
                      name="pincode"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input
                        placeholder="Pin Code"
                        type="number"
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: {
                              ...data?.address,
                              pincode: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div style={{ marginTop: "17px" }}>
                  <div className={classes["two-input"]}>
                    <Form.Item className={classes["input-box-inside"]}>
                      <Select
                        className={classes["select-box-inside"]}
                        defaultValue="Select State"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={options}
                        onChange={(value, op) => {
                          console.log(value, op);
                          setData({
                            ...data,
                            address: {
                              ...data?.address,
                              state: op?.label,
                              city: "Select City",
                            },
                            // state: op?.label,
                          });
                          setCity(
                            City.getCitiesOfState("IN", value).map((city) => {
                              return {
                                value: city.name,
                                label: city.name,
                              };
                            })
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item className={classes["input-box-inside"]}>
                      <Select
                        className={classes["select-box-inside"]}
                        defaultValue="Select City"
                        options={city}
                        value={data?.address?.city}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value, op) => {
                          console.log(value, op);
                          setData({
                            ...data,
                            address: {
                              ...data?.address,
                              city: op?.label,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item className={classes["form-margin"]}>
                  <Text className={classes["terms"]}>
                    By signup you agreed to our{" "}
                    <Link to="/terms" target="_blank">
                      Terms and Conditions
                    </Link>{" "}
                    &{" "}
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                    .
                  </Text>
                </Form.Item>
                <Form.Item style={{ marginTop: "20px", marginBottom: "0px" }}>
                  {/* <Link to="/login"> */}

                  <Button
                    type="primary"
                    onClick={submit}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    style={{
                      width: "100%",
                    }}
                    size="large"
                  >
                    Signup
                  </Button>
                  {/* </Link> */}
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        {/* <div className={classes["login-mobile"]}>
          <MobileSignup />
        </div> */}
      </div>
      <Modal
        open={signupSuccess}
        onOk={setSignupSuccess}
        onCancel={setSignupSuccess}
        footer={null}
        centered
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Checkmark size={50} />
          <div
            style={{
              fontSize: "20px",
              marginTop: "20px",
              color: "#0B3954",
              fontWeight: "500",
            }}
          >
            Sign Up Successfull
          </div>
          <Button
            type="primary"
            style={{
              marginTop: "20px",
            }}
            onClick={login}
          >
            Get OTP
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Signup;

import { Button, Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotLogin = ({ open, setOpen, isCheckout = false }) => {
  return (
    <Modal open={open} onCancel={() => setOpen(false)} footer={null} centered>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Join for Free!
        </div>
        {isCheckout ? (
          <div>Please Login to buy Subscription Plan</div>
        ) : (
          <div>Please log in to see results.</div>
        )}

        <Link to="/login">
          <Button
            type="primary"
            style={{
              marginTop: "20px",
            }}
          >
            Log In
          </Button>
        </Link>
      </div>
    </Modal>
  );
};

export default NotLogin;

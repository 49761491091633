import React from "react";
import { Checkmark } from "react-checkmark";
import Navbar from "../Navbar";
import Footer from "../Footer";
import classes from "./subSuccess.module.css";
import { Button } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";

const SubSuccess = ({ userDetails }) => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // navigate to product/add after 1 sec
    setTimeout(() => {
      navigate("/product/add?showAddNotification=true");
    }, 2000);
  }, []);

  const handleNavigate = () => {
    if (userDetails?.role === "consumer") {
      navigate("/search/manufacturer");
    } else {
      navigate("/search/consumer");
    }
  };
  return (
    <>
      <Navbar />
      <div className={classes["success_container"]}>
        <Checkmark size={70} />
        <h2 style={{ textAlign: "center", marginTop: "30px" }}>
          🎉 You are successfully Subscribed to {searchParams.get("accessType")}{" "}
          {searchParams.get("type")} Plan!
        </h2>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "15px",
            color: "#075C98",
          }}
        >
          To appear in search results, kindly add your Biomass{" "}
          {userDetails?.role === "consumer" ? "Requirement" : "Product"}.
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button type="primary" onClick={handleNavigate} size="large">
            Search{" "}
            {userDetails?.role === "consumer" ? "Manufacturer" : "Consumer"}
          </Button>
          <Link to="/product/add">
            <Button
              type="primary"
              style={{
                marginLeft: "20px",
              }}
              onClick={handleNavigate}
              size="large"
            >
              Add{" "}
              {userDetails?.role === "consumer" ? "Requirements" : "Products"}
            </Button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(SubSuccess);

export const consumer_images = [
  "https://cdn.indhanbazaar.com/webassets/consumer.png",
  "https://cdn.indhanbazaar.com/webassets/consumer-1.png",
  "https://cdn.indhanbazaar.com/webassets/consumer-2.png",
];

export const manufacturer_images = [
  "https://cdn.indhanbazaar.com/webassets/manufacturer.png",
  "https://cdn.indhanbazaar.com/webassets/manufacturer-1.png",
  "https://cdn.indhanbazaar.com/webassets/manufacturer-2.png",
];

export const getDefaultImage = (role, index = 0) => {
  if (role === "consumer") {
    return manufacturer_images[index % manufacturer_images.length];
  } else {
    return consumer_images[index % consumer_images.length];
  }
};

import { Button, Form, Input, Modal, Select, Slider } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Filters = ({ open, setOpen, filters, setFilters, searchResults }) => {
  const searchFilter = () => {
    // console.log(filters);
    searchResults();
    setOpen(false);
  };
  const clearFilters = () => {
    setFilters({
      productType: undefined,
      size: undefined,
      rawMaterial: undefined,
      distance: 100,
    });
    searchResults();
    setOpen(false);
  };
  return (
    <Modal
      title="Filters"
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => clearFilters()}>
          Clear All
        </Button>,
        <Button key="submit" type="primary" onClick={() => searchFilter()}>
          Save
        </Button>,
      ]}
      centered
    >
      <Form name="basic" autoComplete="off" layout="vertical">
        <Form.Item
          //   className={classes["desktop-right-side-formItem"]}
          label="Product Type"
        >
          <Select
            // className={classes["desktop-right-side-select"]}
            placeholder="Product Type"
            value={filters?.productType}
            onChange={(e) => {
              setFilters({ ...filters, productType: e });
            }}
            options={["Briquette", "Pellet"].map((item) => {
              return { label: item, value: item };
            })}
          />
        </Form.Item>
        <Form.Item
          //   className={classes["desktop-right-side-formItem"]}
          label="Size (in mm)"
        >
          <Select
            // className={classes["desktop-right-side-select"]}
            placeholder="Size (in mm)"
            value={filters?.size}
            onChange={(e) => {
              setFilters({ ...filters, size: e });
            }}
            options={["40", "50", "70", "80", "90", "100", "110"].map(
              (item) => {
                return { label: item, value: item };
              }
            )}
          />
        </Form.Item>
        <Form.Item
          //   className={classes["desktop-right-side-formItem"]}
          label="Raw Material"
        >
          <Select
            // className={classes["desktop-right-side-select"]}
            placeholder="Raw Material"
            value={filters?.rawMaterial}
            onChange={(e) => {
              setFilters({ ...filters, rawMaterial: e });
            }}
            options={["MUSTARD HUSK", "GROUNDNUT SHELL", "SAW DUST"].map(
              (item) => {
                return { label: item, value: item };
              }
            )}
          />
        </Form.Item>
        <Form.Item label="Distance(in km)">
          <Slider
            value={filters?.distance}
            onChange={(e) => {
              setFilters({ ...filters, distance: e });
            }}
            marks={{
              0: "0",
              400: "400",
            }}
            max={400}
            tooltip={{
              open: true,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Filters;

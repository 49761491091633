import {
  PDFViewer,
  Document,
  Page,
  Font,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";

const ourGSTNumber = "07AABCT4369C1ZD";
const companyGSTNumber = "08AABCT4369C1ZD";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "/font/Roboto-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/font/Roboto-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "/font/Roboto-Bold.ttf",
      fontWeight: 600,
    },
    {
      src: "/font/Roboto-Bold.ttf",
      fontWeight: 700,
    },
    {
      src: "/font/Roboto-Bold.ttf",
      fontWeight: 800,
    },
  ],
});

const styles = StyleSheet.create({
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
});

const CompanyDetails = ({ children, ...props }) => {
  return (
    <Text
      style={{
        fontSize: "10px",
        color: "rgba(0, 0, 0, 0.6)",
        marginTop: "4px",
        lineHeight: "1.5",
        ...props,
      }}
    >
      {children}
    </Text>
  );
};

const OrderDetails = ({ children }) => {
  return (
    <Text
      style={{
        fontSize: "10px",
        marginTop: "4px",
        lineHeight: "1.5",
      }}
    >
      {children}
    </Text>
  );
};

const TableRowHead = ({ children, ...props }) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: "12px",
      borderBottom: "0.5px solid rgba(0, 0, 0, 0.16)",
      borderTop: "0.5px solid rgba(0, 0, 0, 0.16)",
      fontWeight: "500",
    }}
    {...props}
  >
    {children}
  </View>
);

const TableRowColumn = ({ children, ...props }) => (
  <Text
    style={{
      width: "16%",
      textAlign: "center",

      ...props,
      // paddingLeft: "24px",
    }}
  >
    {children}
  </Text>
);
const TableRowColumnLast = ({ children, ...props }) => (
  <Text
    style={{
      width: "20%",
      textAlign: "right",
      paddingRight: "24px",
    }}
    {...props}
  >
    {children}
  </Text>
);

const TableRow = ({ children, ...props }) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: "12px",
      borderBottom: "0.5px solid rgba(0, 0, 0, 0.16)",
      color: "rgba(0, 0, 0, 0.87)",
    }}
    {...props}
  >
    {children}
  </View>
);

const TableRowColumnWise = ({ children, ...props }) => (
  <View
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingVertical: "12px",
      borderBottom: "0.5px solid rgba(0, 0, 0, 0.16)",
      color: "rgba(0, 0, 0, 0.87)",
      gap: "12px",
    }}
    {...props}
  >
    {children}
  </View>
);

const Invoice = ({ data = {} }) => {
  const isSameAddress = () => {
    console.log(ourGSTNumber.substring(0, 2));
    if (ourGSTNumber.substring(0, 2) === companyGSTNumber.substring(0, 2)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    // <PDFViewer
    //   style={{
    //     margin: "0px",
    //     width: "100%",
    //     height: "99vh",
    //     borderWidth: "0px",
    //     overflow: "hidden",
    //   }}
    // >
    <Document title="Invoice" author="Indhan Bazaar">
      <Page
        style={{
          fontFamily: "Roboto",
          fontSize: "10px",
        }}
        wrap
        size={"A4"}
      >
        <Image
          source={{
            uri: "https://cdn.indhanbazaar.com/webassets/763b68998b716e38dd82.png",
            method: "GET",
          }}
          fixed
          style={styles.pageBackground}
        />

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
          }}
        >
          <Image
            source={{
              uri: "https://cdn.indhanbazaar.com/webassets/e2f3deebbc1db076eeb7.png",
              method: "GET",
            }}
            style={{
              width: "auto",
              height: "30px",
            }}
          />
          <Text
            style={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            GST TAX INVOICE
          </Text>
        </View>
        {/* Company Details */}
        <View
          style={{
            paddingHorizontal: "20px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "40px",
            gap: "20px",
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Image
              source={{
                uri: "https://cdn.indhanbazaar.com/webassets/e71460c075e4e388c5e4.png",
                method: "GET",
                //   headers: { "Cache-Control": "no-cache" },
              }}
              style={{
                width: "28.4px",
                height: "22px",
                marginBottom: "4px",
              }}
            />
            <CompanyDetails fontWeight="500">
              ENVIREMECH INNOVATIONS PVT. LTD.
            </CompanyDetails>
            <CompanyDetails>
              Address: FIRST FLOOR, PLOT-B-19 KH NO-9/22, NAYA BAZAR, Najafgarh,
              New Delhi, South West Delhi, Delhi, 110043
            </CompanyDetails>
            <CompanyDetails>Phone Number: +91 9540580400</CompanyDetails>
            <CompanyDetails>Email ID : enviremech@gmail.com</CompanyDetails>
            <CompanyDetails>GSTIN : 07AAHCE5126D1ZY</CompanyDetails>
          </View>
          <View
            style={{
              width: "50%",
            }}
          >
            <Text>Bill To/ Service To:</Text>
            <Text
              style={{
                fontSize: "10px",
                marginTop: "4px",
                lineHeight: "1.5",
                fontWeight: "500",
              }}
            >
              {data?.partyName}
            </Text>
            <CompanyDetails>Address: {data?.partyAddress}</CompanyDetails>
            <CompanyDetails>
              Phone Number: +91 {data?.partyPhone}
            </CompanyDetails>
            <CompanyDetails>Email ID : {data?.partyEmail}</CompanyDetails>
            <CompanyDetails>GSTIN : {data?.partyGST}</CompanyDetails>
          </View>
        </View>
        {/* Company Details Ends */}
        {/* Orders Details */}
        <View
          style={{
            paddingHorizontal: "20px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "20px",
            paddingBottom: "20px",
            gap: "20px",
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <OrderDetails>Order ID : {data?.orderId}</OrderDetails>
            <OrderDetails>
              Order Date : {moment(data?.orderDate).format("DD/MM/YYYY")}
            </OrderDetails>
          </View>
          <View
            style={{
              width: "50%",
            }}
          >
            <OrderDetails>
              Invoice Date : {moment(data?.invoiceDate).format("DD/MM/YYYY")}
            </OrderDetails>
            <OrderDetails>Invoice Number : {data?.invoiceNumber}</OrderDetails>
            <OrderDetails>HSN/SAC Code : 998313</OrderDetails>
          </View>
        </View>
        {/* Orders Details Ends */}
        {/* Table */}
        <View>
          <TableRowHead>
            <TableRowColumn>Date</TableRowColumn>
            <TableRowColumn>Description</TableRowColumn>
            <TableRowColumn>Service Period</TableRowColumn>
            <TableRowColumn>Amount</TableRowColumn>
            <TableRowColumn>Discount</TableRowColumn>
            <TableRowColumnLast>Total</TableRowColumnLast>
          </TableRowHead>
          <TableRow>
            <TableRowColumn>
              {moment(data?.orderDate).format("DD/MM/YYYY")}
            </TableRowColumn>
            <TableRowColumn textTransform="uppercase">
              INDHAN BAZAAR {data?.tableDescription}
            </TableRowColumn>
            <TableRowColumn>{data?.tableServicePeriod}</TableRowColumn>
            <TableRowColumn>₹ {data?.tablePlanAmount}/-</TableRowColumn>
            <TableRowColumn>₹ {data?.tableDiscount}/-</TableRowColumn>
            <TableRowColumnLast>
              ₹ {data?.tableTotalAmount}/-
            </TableRowColumnLast>
          </TableRow>
          <TableRowColumnWise>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <TableRowColumnLast>Sub Total</TableRowColumnLast>
              <TableRowColumnLast>
                ₹ {data?.tableTotalAmount}/-
              </TableRowColumnLast>
            </View>
            {data?.tableCGST !== "" ? (
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <TableRowColumnLast>SGST @ 9%</TableRowColumnLast>
                  <TableRowColumnLast>₹ {data?.tableSGST}/-</TableRowColumnLast>
                </View>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <TableRowColumnLast>CGST @ 9%</TableRowColumnLast>
                  <TableRowColumnLast>₹ {data?.tableCGST}/-</TableRowColumnLast>
                </View>
              </View>
            ) : (
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <TableRowColumnLast>IGST @ 18%</TableRowColumnLast>
                <TableRowColumnLast>₹ {data?.tableIGST}/-</TableRowColumnLast>
              </View>
            )}
          </TableRowColumnWise>
          <TableRow>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                fontWeight: "500",
              }}
            >
              <TableRowColumnLast>Grand Total</TableRowColumnLast>
              <TableRowColumnLast>
                ₹ {data?.tableGrandTotalAmount}/-
              </TableRowColumnLast>
            </View>
          </TableRow>
        </View>
        {/* Table Ends */}
        {/* Footer */}
        <View
          fixed
          style={{
            height: `120px`,
            paddingTop: "6px",
            position: "absolute",
            bottom: 0,
            width: "100%",
            paddingHorizontal: "24px",
            borderTop: "0.5px solid rgba(0, 0, 0, 0.16)",
          }}
        >
          <View
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  color: "#333333",
                }}
              >
                Payment Method: Online
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#333333",
                  paddingTop: "4px",
                }}
              >
                Payment Date :{" "}
                {moment(data?.orderDate).format("DD/MM/YYYY hh:mm A")}
              </Text>
            </View>
            <View
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Image
                source={{
                  uri: "https://cdn.indhanbazaar.com/webassets/c9383bd4796b14004d65.png",
                  method: "GET",
                  //   headers: { "Cache-Control": "no-cache" },
                }}
                style={{
                  width: "80px",
                  height: "24px",
                  marginBottom: "4px",
                }}
              />
              <Text
                style={{
                  fontSize: "14px",
                  color: "#333333",
                  paddingTop: "4px",
                }}
              >
                Thank You!
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default Invoice;

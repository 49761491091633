const ACTIONS = {
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAIL: "FETCH_USER_FAIL",

  SIGN_UP: "SIGN_UP",
  LOGOUT: "LOGOUT",
};

export default ACTIONS;

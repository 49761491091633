import { Button, Carousel, Col, Modal, Row, Space } from "antd";
import { Obj1, Obj2, Obj3 } from "../../assets";
import styles from "../NewPage/newPage.module.css";
import { useRef } from "react";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

const ProductModal = ({ open, setOpen, productInfo = {} }) => {
  const sliderRef = useRef();

  return (
    <Modal
      title="Product Details"
      open={open}
      closable
      onCancel={() => setOpen(false)}
      footer={[<Button onClick={() => setOpen(false)}>Close</Button>]}
      centered
      width={"560px"}
    >
      <div className={styles.productModalCont}>
        <div className={styles.carousel}>
          <div className={styles.left} onClick={() => sliderRef.current.prev()}>
            <LeftOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            className={styles.right}
            onClick={() => sliderRef.current.next()}
          >
            <RightOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <Carousel
            style={{ width: "100%" }}
            ref={(ref) => (sliderRef.current = ref)}
            dots={false}
            // autoplay
          >
            <div className={styles.imgCont}>
              {productInfo?.photo?.map((photo) => (
                <img src={photo} />
              ))}
            </div>
          </Carousel>
        </div>
        <div className={styles.product_row_container}>
          <div className={styles.product_row}>
            <div>Raw Material</div>
            <div>{productInfo?.biomassProductType?.rawMaterial}</div>
          </div>
          <div className={styles.product_row}>
            <div>Size (mm)</div>
            <div>{productInfo?.biomassProductType?.size} mm</div>
          </div>
          <div className={styles.product_row}>
            <div>GCV (Cal/gm)</div>
            <div>
              {
                productInfo?.biomassProductType?.productQuality
                  ?.grossCalorificValue
              }
            </div>
          </div>
          <div className={styles.product_row}>
            <div>Ash Content (%)</div>
            <div>
              {productInfo?.biomassProductType?.productQuality?.ashContent}
            </div>
          </div>
          <div className={styles.product_row}>
            <div>Moisture Content (%)</div>
            <div>
              {productInfo?.biomassProductType?.productQuality?.moistureContent}
            </div>
          </div>
          <div className={styles.product_row}>
            <div>Packaging</div>
            <div>{productInfo?.biomassProductType?.packagingType}</div>
          </div>
          <div className={styles.product_row}>
            <div>Min Order Qty.</div>
            <div>{productInfo?.biomassProductType?.minimumOrderQty}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;

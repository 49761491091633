import { useState } from "react";
import { Link } from "react-router-dom";
import {
  LogoutOutlined,
  CopyOutlined,
  EditOutlined,
  DiffOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { LogoWhite, Logo, Profile, BlackArrowDown } from "../../assets";
import { Button, Space, Typography, Popover, Divider } from "antd";
import MobileNavbar from "./MobileNavbar";
import classes from "../../styles/navbar.module.css";
import { connect } from "react-redux";
import { logout } from "../../User/UserActions";
const { Text } = Typography;

const index = ({ login = false, userDetails, logouts }) => {
  const AdminContent = () => {
    return (
      <div>
        <div
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          // onClick={() => props.logouts()}
        >
          <Link
            to="/profile"
            style={{
              color: "inherit",
            }}
          >
            <EditOutlined />{" "}
            <span style={{ paddingLeft: "8px" }}>Edit Profile</span>
          </Link>
        </div>
        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
        <div
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          // onClick={() => props.logouts()}
        >
          <Link
            to="/product/add"
            style={{
              color: "inherit",
            }}
          >
            <DiffOutlined />{" "}
            <span style={{ paddingLeft: "8px" }}>
              {userDetails?.role === "consumer"
                ? "Add New Requirement"
                : "List New Product"}
            </span>
          </Link>
        </div>
        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
        <div
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          // onClick={() => props.logouts()}
        >
          <Link
            to="/product/list"
            style={{
              color: "inherit",
            }}
          >
            <CopyOutlined />{" "}
            <span style={{ paddingLeft: "8px" }}>
              {userDetails?.role === "consumer"
                ? "Requirement List"
                : "Product List"}
            </span>
          </Link>
        </div>
        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
        <div
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          // onClick={() => props.logouts()}
        >
          <Link
            to="/subscription_history"
            style={{
              color: "inherit",
            }}
          >
            <HistoryOutlined />{" "}
            <span style={{ paddingLeft: "8px" }}>Subscription History</span>
          </Link>
        </div>
        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
        <div
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => logouts()}
        >
          <LogoutOutlined /> <span style={{ paddingLeft: "8px" }}>Logout</span>
        </div>
      </div>
    );
  };
  const Title = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div>
          <Profile width={"60px"} height={"60px"} />
        </div>
        <div style={{ marginTop: "10px", marginLeft: "10px" }}>
          <div>
            <Text>{truncateText(userDetails?.ownername)}</Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>{userDetails?.phone}</Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px", textTransform: "capitalize" }}>
              Role: {userDetails?.role}
            </Text>
          </div>
        </div>
      </div>
    );
  };
  const truncateText = (str = "") => {
    return str?.length > 10 ? str?.substring(0, 10) + "..." : str;
  };
  console.log(userDetails);
  return (
    <>
      <div
        style={{
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 10,
          boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.08)",
        }}
        className={
          login === false
            ? classes["navbar-dekstop"]
            : classes["navbar-dekstop-logined"]
        }
      >
        <Link to="/" style={{ zIndex: 1 }}>
          {!login && <Logo />}
          {login && <LogoWhite />}
        </Link>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2.4rem",
          }}
        >
          <Link to="/" style={{ zIndex: 1, color: "black" }}>
            Home
          </Link>
          {!userDetails?._id && (
            <>
              <Link to="/search/consumer" style={{ zIndex: 1, color: "black" }}>
                Consumer
              </Link>
              <Link
                to="/search/manufacturer"
                style={{ zIndex: 1, color: "black" }}
              >
                Manufacturer
              </Link>
            </>
          )}
          {userDetails?.role === "manufacturer" && (
            <Link to="/search/consumer" style={{ zIndex: 1, color: "black" }}>
              Search Consumer
            </Link>
          )}
          {userDetails?.role === "consumer" && (
            <Link
              to="/search/manufacturer"
              style={{ zIndex: 1, color: "black" }}
            >
              Search Manufacturer
            </Link>
          )}
          {userDetails?.isSub === true ? (
            <Link to="/product/list" style={{ zIndex: 1, color: "black" }}>
              {userDetails?.role === "consumer"
                ? "Requirement List"
                : "Product List"}
            </Link>
          ) : (
            <Link to="/subscription" style={{ zIndex: 1, color: "black" }}>
              Pricing
            </Link>
          )}
        </div>
        {!userDetails?._id && (
          <Link to="/login">
            <Button type="primary">Log In</Button>
          </Link>
        )}
        {userDetails?._id && (
          <Popover
            placement="bottomRight"
            title={Title}
            content={AdminContent}
            trigger="click"
          >
            <Space
              style={{
                cursor: "pointer",
              }}
            >
              <div className={classes["header-avatar-div"]}>
                <Profile width={"34px"} height={"34px"} />
              </div>
              <Text
                style={{
                  color: "black",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                {truncateText(userDetails?.ownername)}
              </Text>
              <BlackArrowDown
                width={"25px"}
                height={"25px"}
                style={{ marginTop: "5px" }}
              />
            </Space>
          </Popover>
        )}
      </div>
      <div className={classes["navbar-mobile"]}>
        <MobileNavbar userDetails={userDetails} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logouts: (params) => dispatch(logout(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);

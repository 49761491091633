import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Footer from "../Footer";
import Content from "../Content";
import Navbar from "../Navbar";
import privacy from "../Content/Privacy.md";

const Policy = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    fetch(privacy)
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        setText(data);
      });
  }, [text]);

  return (
    <div>
      <Navbar />
      <div
        style={{
          marginTop: "30px",
        }}
      >
        <Content data={text} />
      </div>
      <Footer />
    </div>
  );
};

export default Policy;

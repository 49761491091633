import { Button, Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const UnlockUser = ({
  open,
  setOpen,
  setIsUnlocked,
  setIsConfetti,
  unlockUserRequest,
}) => {
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            // setIsUnlocked(true);
            // setIsConfetti(true);
            // setOpen(false);
            unlockUserRequest();
          }}
        >
          Unlock Now
        </Button>,
      ]}
      centered
      title="Unlock User?"
    >
      Are you sure to unlock details of this User?
    </Modal>
  );
};

export default UnlockUser;

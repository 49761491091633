import ACTIONS from "./UserActionsType";
import setAuthToken from "./setAuthToken";

export const login = (params = {}) => {
  localStorage.setItem("token", params.token);
  setAuthToken(localStorage.getItem("token"));
  return {
    type: ACTIONS.SIGN_UP,
    payload: params.user,
  };
};

export const fetchUser = (params = {}) => {
  // console.log("I AM HERE", params);
  return {
    type: ACTIONS.FETCH_USER_REQUEST,
    payload: params,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.pathname = "/";
  return {
    type: ACTIONS.LOGOUT,
  };
};

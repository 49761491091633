import React from "react";
import { Logo } from "../../assets";
import {
  MobileOutlined,
  MailOutlined,
  LinkedinFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import { Typography, Select, Button, Input, Space } from "antd";
import classes from "../../styles/subscription.module.css";
const { Text } = Typography;
const MobileSubscription = () => {
  const options = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "Yiminghe",
      label: "yiminghe",
    },
  ];
  return (
    <div>
      {/* <div className={classes["desktop-header-container"]}>
        <div className={classes["desktop-header"]}>
          <div className={classes["desktop-header-left"]}>
            <div className={classes["desktop-header-left-content"]}>
              <div style={{ marginTop: "-20px", marginRight: "10px" }}>
                <Text className={classes["desktop-header-left-number"]}>
                  44
                </Text>
              </div>
              <div>
                <div>
                  <Text className={classes["desktop-header-left-text"]}>
                    Total
                  </Text>
                </div>
                <div>
                  <Text className={classes["desktop-header-left-text"]}>
                    Consumers in Roorkee
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className={classes["desktop-header-right"]}>
            <div className={classes["desktop-header-select-div"]}>
              <Select
                className={classes["desktop-header-select"]}
                defaultValue="Select Location"
                options={options}
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className={classes["desktop-content"]}>
        <div className={classes["desktop-content-container"]}>
          <div className={classes["desktop-content-left"]}>
            <div className={classes["desktop-content-top"]}>
              <div className={classes["desktop-content-top-container"]}>
                <div className={classes["desktop-content-top-left"]}>
                  <div>
                    <Text className={classes["desktop-content-top-state"]}>
                      Normal
                    </Text>
                  </div>
                  <div style={{ marginTop: "-10px" }}>
                    <Text className={classes["desktop-content-top-prime"]}>
                      Prime
                    </Text>
                  </div>
                </div>
                <div className={classes["desktop-content-top-right"]}>
                  <Text className={classes["desktop-content-top-price"]}>
                    $499
                  </Text>
                </div>
              </div>
            </div>
            <div>
              <div className={classes["desktop-content-points-container"]}>
                <ul>
                  <li className={classes["desktop-content-points"]}>
                    Asses of Own State Users with their requirements and
                    conditions
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Known as "Indhan Mitra" with unique ID
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Subscription valid for 3 months only
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    5 UNLOCKS / DAY
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Unlimited Leads
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    As per business real time rating (given by users) 4.0 for
                    consicutives 3 months, your Company banner will appear on
                    front page (free of cost)
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Advertisement cost of showing your company banner will be Rs
                    50 per day on e-commerce portal
                  </li>
                </ul>
              </div>
              <div className={classes["desktop-content-button-div"]}>
                <Button className={classes["desktop-content-button"]}>
                  Choose Plan
                </Button>
              </div>
            </div>
          </div>
          <div className={classes["desktop-content-left"]}>
            <div className={classes["desktop-content-top"]}>
              <div className={classes["desktop-content-popular"]}>
                <Text
                  style={{
                    color: "white",
                    marginLeft: "5px",
                    fontSize: "16px",
                  }}
                >
                  Most Popular
                </Text>
              </div>
              <div className={classes["desktop-content-top-container"]}>
                <div className={classes["desktop-content-top-left"]}>
                  <div>
                    <Text className={classes["desktop-content-top-state"]}>
                      State
                    </Text>
                  </div>
                  <div style={{ marginTop: "-10px" }}>
                    <Text className={classes["desktop-content-top-prime"]}>
                      Prime
                    </Text>
                  </div>
                </div>
                <div className={classes["desktop-content-top-right"]}>
                  <Text className={classes["desktop-content-top-price"]}>
                    $499
                  </Text>
                </div>
              </div>
            </div>
            <div>
              <div className={classes["desktop-content-points-container"]}>
                <ul>
                  <li className={classes["desktop-content-points"]}>
                    Asses of Own State Users with their requirements and
                    conditions
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Known as "Indhan Mitra" with unique ID
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Subscription valid for 3 months only
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    5 UNLOCKS / DAY
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Unlimited Leads
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    As per business real time rating (given by users) 4.0 for
                    consicutives 3 months, your Company banner will appear on
                    front page (free of cost)
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Advertisement cost of showing your company banner will be Rs
                    50 per day on e-commerce portal
                  </li>
                </ul>
              </div>
              <div className={classes["desktop-content-button-div"]}>
                <Button className={classes["desktop-content-button"]}>
                  Choose Plan
                </Button>
              </div>
            </div>
          </div>
          <div className={classes["desktop-content-left"]}>
            <div className={classes["desktop-content-top"]}>
              <div className={classes["desktop-content-top-container"]}>
                <div className={classes["desktop-content-top-left"]}>
                  <div>
                    <Text className={classes["desktop-content-top-state"]}>
                      State
                    </Text>
                  </div>
                  <div style={{ marginTop: "-10px" }}>
                    <Text className={classes["desktop-content-top-prime"]}>
                      Prime
                    </Text>
                  </div>
                </div>
                <div className={classes["desktop-content-top-right"]}>
                  <Text className={classes["desktop-content-top-price"]}>
                    $499
                  </Text>
                </div>
              </div>
            </div>
            <div>
              <div className={classes["desktop-content-points-container"]}>
                <ul>
                  <li className={classes["desktop-content-points"]}>
                    Asses of Own State Users with their requirements and
                    conditions
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Known as "Indhan Mitra" with unique ID
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Subscription valid for 3 months only
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    5 UNLOCKS / DAY
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Unlimited Leads
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    As per business real time rating (given by users) 4.0 for
                    consicutives 3 months, your Company banner will appear on
                    front page (free of cost)
                  </li>
                  <li className={classes["desktop-content-points"]}>
                    Advertisement cost of showing your company banner will be Rs
                    50 per day on e-commerce portal
                  </li>
                </ul>
              </div>
              <div className={classes["desktop-content-button-div"]}>
                <Button className={classes["desktop-content-button"]}>
                  Choose Plan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes["desktop-footer"]}>
        <div className={classes["desktop-footer-container"]}>
          <div className={classes["desktop-footer-content"]}>
            <div className={classes["desktop-footer-left"]}>
              <div>
                <Logo />
              </div>
              <div style={{ marginTop: "40px" }}>
                <Text>
                  Lorem ipsum dolor sit amet consectetur. Diam amet dictum
                  varius mi cras diam leo phasellus duis.
                </Text>
              </div>
              <div style={{ marginTop: "20px" }}>
                <MobileOutlined className={classes["desktop-icon"]} />
                <Text
                  className={classes["desktop-icon"]}
                  style={{ marginLeft: "10px" }}
                >
                  +91 XXXXXXXXXX
                </Text>
              </div>
              <div style={{ marginTop: "20px" }}>
                <MailOutlined className={classes["desktop-icon"]} />
                <Text
                  className={classes["desktop-icon"]}
                  style={{ marginLeft: "10px" }}
                >
                  loremipsum@gmail.com
                </Text>
              </div>
            </div>
            <div className={classes["desktop-footer-right"]}>
              <div style={{ marginTop: "30px" }}>
                <Text>Contact Us</Text>
              </div>
              <div style={{ marginTop: "8px" }}>
                {/* <Space.Compact
                  style={{
                    width: "100%",
                  }}
                >
                  <Input placeholder="Enter Email" />
                  <Button className={classes["send-button"]}>Send</Button>
                </Space.Compact> */}
              </div>
              <div style={{ marginTop: "36px" }}>
                <LinkedinFilled
                  style={{
                    fontSize: "24px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                />
                <TwitterOutlined
                  style={{
                    fontSize: "24px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginLeft: "25px",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Text
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "12px",
                marginBottom: "30px",
              }}
            >
              Terms & Conditions | Copyright @ indhanbazaar
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSubscription;

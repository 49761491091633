import { useState } from "react";
import classes from "../../styles/signup.module.css";
import {
  Button,
  Typography,
  Input,
  Radio,
  Form,
  InputNumber,
  Select,
} from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;
const plainOptions = ["Manufacturer", "Consumer"];
const options = [
  {
    value: "jack",
    label: "Jack",
  },
  {
    value: "lucy",
    label: "Lucy",
  },
  {
    value: "Yiminghe",
    label: "yiminghe",
  },
];
const MobileSignup = () => {
  const [value, setValue] = useState("Manufacturer");
  const onChange = ({ target: { value } }) => {
    console.log("radio1 checked", value);
    setValue(value);
  };
  return (
    <div className={classes["mobile-container"]}>
      <div className={classes["top-left"]}></div>
      <div className={classes["bottom-right"]}></div>
      <div className={classes["mobile-content"]}>
        <div>
          <Text className={classes["welcome"]}>Sign Up</Text>
        </div>
        <Form name="basic" autoComplete="off">
          <Form.Item
            label="User Type"
            name="username"
            rules={[
              {
                required: true,
              },
            ]}
            className={classes["form-margin"]}
          >
            <Radio.Group
              options={plainOptions}
              onChange={onChange}
              value={value}
            />
          </Form.Item>

          <Form.Item style={{ marginTop: "15px", marginBottom: "0px" }}>
            <Input
              className={classes["input-box"]}
              placeholder="Company Name"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="User Name"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <InputNumber
              className={classes["input-box"]}
              addonBefore="+91"
              placeholder="contact"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="GST No"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Select
              className={classes["select-box-inside"]}
              defaultValue="Select State"
              options={options}
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Select
              className={classes["select-box-inside"]}
              defaultValue="Select State"
              options={options}
              size="large"
            />
          </Form.Item>

          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="Town"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="Landmark"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="Street Name"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="Plot No"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Input
              className={classes["input-box"]}
              placeholder="Pin Code"
              size="large"
            />
          </Form.Item>
          <Form.Item className={classes["form-margin"]}>
            <Text className={classes["terms"]}>
              By signup you agreed to our terms and conditions.
            </Text>
          </Form.Item>
          <Form.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Link to="/login">
              <Button type="primary" style={{ width: "100%" }}>
                Get OTP
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default MobileSignup;

import React, { useState } from "react";
import classes from "../../styles/subscription.module.css";
import { Logo } from "../../assets";
import {
  MobileOutlined,
  MailOutlined,
  LinkedinFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import { Typography, Select, Button, Input, Space, message } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";

const { Text } = Typography;

const links = [
  {
    label: "Home",
    link: "/",
    isInternal: true,
  },
  {
    label: "Pricing",
    link: "/subscription",
    isInternal: true,
  },
  {
    label: "Blogs",
    link: "https://blog.indhanbazaar.com",
    isInternal: false,
  },
  {
    label: "Privacy Policy",
    link: "/privacy-policy",
    isInternal: true,
  },
  {
    label: "Cancellation Policy",
    link: "/cancellation-policy",
    isInternal: true,
  },
  {
    label: "Terms & Conditions",
    link: "/terms",
    isInternal: true,
  },
];

const Footer = () => {
  const [details, setDetails] = useState({ name: "", phoneNumber: "" });
  const submit = () => {
    if (details?.name === "" || details?.phoneNumber === "") {
      return message.error("Please fill the fields");
    }
    axios
      .post("/contact/create", {
        name: details.name,
        contact: details.phoneNumber,
      })
      .then((res) => {
        message.success(
          "Thanks for contacting us! We will get back to you soon"
        );
      })
      .catch((err) => {
        if (err.response.status == 409) {
          message.error("Already Subscribed");
          return;
        }
        message.error("Something went wrong");
      });
  };
  return (
    <div className={classes["desktop-footer"]}>
      <div className={classes["desktop-footer-container"]}>
        <div>
          <Logo />
        </div>
        <div className={classes["desktop-footer-content"]}>
          <div className={classes["desktop-footer-left"]}>
            <div>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Address
              </Text>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
              className={classes["footer-content"]}
            >
              <Text>
                FIRST FLOOR, PLOT-B-19 KH NO-9/22, NAYA BAZAR, Najafgarh, New
                Delhi, South West Delhi, Delhi, 110043
              </Text>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
              className={classes["footer-content"]}
            >
              <Text>
                <b>Product By:</b> ENVIREMECH INNOVATIONS PRIVATE LIMITED
              </Text>
            </div>
            <div
              style={{ marginTop: "20px" }}
              className={classes["footer-content"]}
            >
              <MobileOutlined className={classes["desktop-icon"]} />
              <Link to="tel:+91 9540580400">
                <Text
                  className={classes["desktop-icon"]}
                  style={{ marginLeft: "10px" }}
                >
                  +91 9540580400
                </Text>
              </Link>
            </div>
            <div
              style={{ marginTop: "20px" }}
              className={classes["footer-content"]}
            >
              <MailOutlined className={classes["desktop-icon"]} />
              <Link to="mailto:info@indhanbazaar.com">
                <Text
                  className={classes["desktop-icon"]}
                  style={{ marginLeft: "10px" }}
                >
                  info@indhanbazaar.com
                </Text>
              </Link>
            </div>
          </div>
          <div className={classes["desktop-footer-left"]}>
            <div>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Useful Links
              </Text>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
              className={classes["footer-content"]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {links?.map((item) =>
                  item?.isInternal ? (
                    <div>
                      <Link to={`${item?.link}`}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {item?.label}
                        </Text>
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <a href={`${item?.link}`} target="_blank">
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {item?.label}
                        </Text>
                      </a>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className={classes["desktop-footer-right"]}>
            <div>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Contact Us
              </Text>
            </div>
            <div style={{ marginTop: "8px" }}>
              {/* <Space.Compact
                  style={{
                    width: "100%",
                  }}
                >
                  <Input placeholder="Enter Email" />
                  <Button className={classes["send-button"]}>Send</Button>
                </Space.Compact> */}
              <div className={classes["footer_input_container"]}>
                <input
                  placeholder="Name"
                  className={classes["footer_input"]}
                  onChange={(e) => {
                    setDetails({ ...details, name: e.target.value });
                  }}
                />
                <input
                  placeholder="Contact Number"
                  className={classes["footer_input"]}
                  type="number"
                  onChange={(e) => {
                    setDetails({ ...details, phoneNumber: e.target.value });
                  }}
                />
                <div
                  className={classes["footer_btn"]}
                  onClick={() => {
                    submit();
                  }}
                >
                  Send
                </div>
              </div>
            </div>
            <div style={{ marginTop: "36px" }}>
              <Link
                to="https://www.linkedin.com/company/indhan-bazaar/"
                target="_blank"
              >
                <LinkedinFilled
                  style={{
                    fontSize: "24px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                />
              </Link>
              <Link to="https://twitter.com/indhanbazaar" target="_blank">
                <TwitterOutlined
                  style={{
                    fontSize: "24px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginLeft: "25px",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <Text style={{ color: "rgba(0, 0, 0, 0.8)", fontSize: "12px" }}>
            Copyright @ indhanbazaar
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../assets";
import classes from "../../styles/navbar.module.css";
import { MenuOutlined } from "@ant-design/icons";
import MobileNavbarMenu from "./MobileNavbarMenu";
import Hamburger from "hamburger-react";
const MobileNavbar = ({ userDetails }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleChange = () => {
    setOpenModal(true);
    console.log("clicked");
  };
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className={classes["navbar-container"]}>
        <Link to="/" style={{ zIndex: 1 }}>
          <Logo />
        </Link>
        <div className={classes["menu-icon"]}>
          <Hamburger toggled={openModal} toggle={setOpenModal} />
        </div>
      </div>
      {openModal === true && (
        <MobileNavbarMenu
          openModal={openModal}
          setOpenModal={setOpenModal}
          userDetails={userDetails}
        />
      )}
    </div>
  );
};

export default MobileNavbar;

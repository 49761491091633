import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import classes from "./checkout.module.css";
import { Button, Divider, Input, Space, message } from "antd";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { marked } from "marked";

const Checkout = () => {
  let navigate = useNavigate();
  let { id, orderId } = useParams();
  const [plan, setPlan] = React.useState({});
  const [couponCode, setCouponCode] = React.useState("");
  const [couponData, setCouponData] = React.useState({});
  const [isCouponApplied, setIsCouponApplied] = React.useState(false);
  useEffect(() => {
    fetchPlan();
  }, []);
  const fetchPlan = () => {
    axios.get(`/plan/${id}`).then((res) => {
      // calculate gst and total
      let gst = 0;
      let total = 0;
      gst = (Number(res?.data?.price) * 18) / 100;
      total = Number(res?.data?.price) + gst;
      res.data.gst = gst;
      res.data.total = total;
      console.log(res.data);
      setPlan(res.data);
    });
  };
  const applyCoupon = () => {
    const obj = {
      planId: id,
      code: couponCode,
    };
    axios
      .post("/validatecoupon", obj)
      .then((res) => {
        console.log(res.data);
        if (res?.data?.success) {
          message.success(res?.data?.message);
          // setCouponData(res?.data);
          // setIsCouponApplied(true);
        }
        // console.log(res.data);
      })
      .catch((err) => {
        // setIsCouponApplied(false);
        message.error(err?.response?.data?.message);
        console.log(err.response.data.message);
      });
  };
  const subscribe = () => {
    const obj = {
      planid: id,
      couponid: isCouponApplied ? couponData?.couponId : null,
    };
    axios.post("/subscribe", obj).then((res) => {
      console.log(res.data);
    });
  };
  const verifyPayment = () => {
    const request = {
      order_id: orderId,
      planId: id,
    };
    axios
      .post("/verifypayment", request)
      .then((res) => {
        message.success("Subscribe Successfull");
        navigate(
          `/sub_success?accessType=${plan?.accessType}&type=${plan?.type}`
        );
        window.location.reload();
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };
  return (
    <>
      <Navbar />
      <div className={classes["desktop-container"]}>
        <div className={classes["main_container"]}>
          <div className={classes["details_container"]}>
            <div
              style={{
                fontSize: "16px",
                color: "#075C98",
              }}
            >
              {plan?.accessType}
            </div>
            <div
              style={{
                fontSize: "30px",
                fontWeight: "600",
                color: "#075C98",
              }}
            >
              {plan?.type}{" "}
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                (for {plan?.duration} months)
              </span>
            </div>
            <div></div>
            <article
              dangerouslySetInnerHTML={{
                __html: marked(plan?.description ?? ""),
              }}
            ></article>
          </div>
          <div className={classes["desktop_view"]}>
            <Divider
              type="vertical"
              style={{
                height: "auto",
              }}
            />
          </div>
          <div className={classes["mobile_view"]}>
            <Divider
              type="horizontal"
              style={{
                height: "auto",
              }}
            />
          </div>
          <div className={classes["price_container"]}>
            <div
              style={{
                width: "100%",
              }}
            >
              {/* <Input.Group
                compact
                style={{
                  width: "100%",
                }}
              >
                <Input
                  style={{
                    width: "calc(100% - 100px)",
                  }}
                  placeholder="Enter Coupon Code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <Button
                  type="primary"
                  style={{
                    width: "100px",
                  }}
                  onClick={applyCoupon}
                >
                  Apply
                </Button>
              </Input.Group> */}
              <div
                style={{
                  fontSize: "14px",
                  textDecorationLine: "underline",
                  color: "rgba(0, 0, 0, 0.6)",
                  marginTop: "2.5rem",
                }}
              >
                Summary
              </div>
              <div
                style={{
                  marginTop: "1.2rem",
                  color: "rgba(0, 0, 0, 0.6)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.8rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Amount</div>
                  <div>₹{plan?.price?.toLocaleString("en-IN")}</div>
                </div>
                {/** TODO: Need To UnComment after Free Trial Over  */}
                {/* {isCouponApplied && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Discount</div>
                    <div>
                      - ₹{couponData?.discountedAmount?.toLocaleString("en-IN")}
                    </div>
                  </div>
                )} */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/** TODO: Need To UnComment after Free Trial Over */}
                  <div>GST(18%)</div>
                  <div>-</div>
                  {/* {isCouponApplied ? (
                    <div>₹{couponData?.taxPaid?.toLocaleString("en-IN")}</div>
                  ) : (
                    <div>₹{plan?.gst?.toLocaleString("en-IN")}</div>
                  )} */}
                </div>
              </div>
              <Divider type="horizontal" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "500",
                }}
              >
                <div>Total Amount</div>
                <div>₹{plan?.price?.toLocaleString("en-IN")}</div>
                {/** TODO: Need To UnComment after Free Trial Over */}
                {/* {isCouponApplied ? (
                  <div>
                    ₹{couponData?.grandTotalAmount?.toLocaleString("en-IN")}
                  </div>
                ) : (
                  <div>₹{plan?.total?.toLocaleString("en-IN")}</div>
                )} */}
              </div>
              <Button
                type="primary"
                size="large"
                style={{
                  width: "100%",
                  marginTop: "1.5rem",
                }}
                onClick={verifyPayment}
              >
                Subscribe Free 🚀
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Checkout;

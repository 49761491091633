import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { Store } from "./Config/Store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["x-access-token"] = `${
  localStorage.getItem("token") === null
    ? ``
    : `${localStorage.getItem("token")}`
}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <App history={history} />
  </Provider>
);

import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import styles from "./home.module.css";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import SubscriptionModal from "../Modals/subscription";

const why_ib_data = [
  {
    title: "Biofuel Manufacturers",
    description:
      "Enhanced market reach PAN India , increased green fuel brand exposure (Local to Global), competitive advantage, and streamlined sales process for biofuel manufacturers on Indhan Bazaar.",
    image: "https://cdn.indhanbazaar.com/webassets/0eacb1751646855bc461.png",
  },
  {
    title: "Biofuel Consumers",
    description:
      "If you utilise biomass fuel, you can choose the closest, most suitable, and trustworthy manufacturers by following your intuition. Indhan Bazaar gave you access to information, a dependable platform, affordable solutions, open reviews, and cost-effective biomass product options.",
    image: "https://cdn.indhanbazaar.com/webassets/8027c033aa77303720ab.png",
  },
  {
    title: "Verified Business Services",
    description:
      "At Indhan Bazaar, we prioritize reliability. For your convenience and satisfaction, we gather all the valid information throughout the login procedure and cross-check it with relevant portals and local authorities. The foundation of business is transparency.",
    image: "https://cdn.indhanbazaar.com/webassets/b804122ac292fb9f4389.png",
  },
];

const manufacturer_data = [
  {
    image: "https://cdn.indhanbazaar.com/webassets/685be72e8a4beaf36d1e.png",
    title: "Expand Your Market Reach",
    description:
      "Indhan Bazaar connects you with a vast network of verified businesses, allowing you to showcase your biomass products to a wider circles of users and tap into new markets.",
  },
  {
    image: "https://cdn.indhanbazaar.com/webassets/7854bd3a358e08decd59.png",
    title: "Streamline Your Sales Process",
    description:
      "By listing your Biomass products on Indhan Bazaar, you gain access to a convenient online platform that simplifies the sales process, making it easier for potential customers to discover, evaluate, and purchase your offerings.",
  },
];

const consumer_data = [
  {
    image: "https://cdn.indhanbazaar.com/webassets/2d98d4c8166eb53103f9.png",
    title: "Find Trusted Suppliers",
    description:
      " Indhan Bazaar exclusively features verified businesses, ensuring that you connect with reliable manufacturers who offer high-quality products and services. For every supplier, timely delivery and high quality are essential.",
  },
  {
    image: "https://cdn.indhanbazaar.com/webassets/0d6f6c6a00fbed7ddc78.png",
    title: "Effortless Product Sourcing",
    description:
      "With Indhan Bazaar's intuitive platform, you can easily browse through a wide range of products, compare prices, and access detailed information, enabling you to make informed purchasing decisions efficiently.",
  },
];

const Home = ({ userDetails }) => {
  const [isSubModal, setIsSubModal] = React.useState(false);

  useEffect(() => {
    const isSubClosed = localStorage.getItem("isSubClosed");
    if (userDetails?._id && userDetails?.isSub === false && !isSubClosed) {
      setIsSubModal(true);
    }
  }, [userDetails]);

  const onCancelSubModal = () => {
    localStorage.setItem("isSubClosed", true);
    setIsSubModal(false);
  };

  return (
    <div>
      <Navbar />
      <div className={styles.home_container}>
        <div className={styles.header_container}>
          <div>
            <div className={styles.header_title}>
              Discover, Connect, Expand Your Business Beyond Boundaries with
              Indhan Bazaar.
            </div>
            <div className={styles.header_description}>
              Unlock the potential of biofuel business with Indhan Bazaar
              platform, connecting yourself with buyers and sellers in your
              local area. Whether you're looking to purchase biofuel for your
              business or searching for interested buyers, our platform serve
              you an efficient and remarkable solution.
            </div>
            <div className={styles.header_buttons}>
              {!userDetails?._id && (
                <>
                  <Link to="/search/manufacturer">
                    <Button type="primary" size="large">
                      Get Manufacturer
                    </Button>
                  </Link>
                  <Link to="/search/consumer">
                    <Button type="primary" size="large">
                      Get Consumer
                    </Button>
                  </Link>
                </>
              )}
              {userDetails?.role === "manufacturer" && (
                <Link to="/search/consumer">
                  <Button type="primary" size="large">
                    Get Consumer
                  </Button>
                </Link>
              )}
              {userDetails?.role === "consumer" && (
                <Link to="/search/manufacturer">
                  <Button type="primary" size="large">
                    Get Manufacturer
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div>
            <img
              src="https://cdn.indhanbazaar.com/webassets/bcaed3953de394d3be31.png"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className={styles.about_container}>
        <div className={styles.about_title}>About</div>
        <div className={styles.about_description}>
          Welcome to Indhan Bazaar, your ultimate online platform for connecting
          manufacturers and consumers in the realm of biomass products. As a
          comprehensive platform, we empower businesses to expand their reach,
          discover cost-effective biomass options, and unlock new markets for
          their products. Our verified business listings ensure trust and
          reliability, while our user-friendly interface simplifies the sourcing
          and purchasing process for consumers. We also assist you in locating
          qualified and trustworthy business partners who can propel your
          company's expansion, improve sustainability, and revolutionize the
          biomass sectors with effective supply chain management solutions.
        </div>
      </div>
      <div className={styles.home_container}>
        <div className={styles.why_ib_title}>Why Indhan Bazaar?</div>
        <div className={styles.why_ib_card_container}>
          {why_ib_data?.map((item) => (
            <div className={styles.why_ib_card}>
              <img src={item?.image} height="48px" width="48px" />
              <div className={styles.why_ib_card_title}>{item?.title}</div>
              <div className={styles.why_ib_card_description}>
                {item?.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.manufactuer_container}>
        <div className={styles.manufactuer_title}>
          Amplify Your Manufacturing Business with Indhan Bazaar
        </div>
        <div className={styles.manufactuer_card_container}>
          {manufacturer_data?.map((item) => (
            <div className={styles.manufactuer_card}>
              <img src={item?.image} height="56px" width="56px" />
              <div className={styles.manufactuer_card_content_container}>
                <div>{item?.title}</div>
                <div>{item?.description}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.manufactuer_button}>
          <Button type="primary" size="large">
            Get Manufacturer
          </Button>
        </div>
      </div>
      <div className={styles.consumer_container}>
        <div className={styles.manufactuer_title}>
          Discover and Access a World of Quality Products on Indhan Bazaar
        </div>
        <div className={styles.manufactuer_card_container}>
          {consumer_data?.map((item) => (
            <div className={styles.manufactuer_card}>
              <img src={item?.image} height="56px" width="56px" />
              <div className={styles.manufactuer_card_content_container}>
                <div>{item?.title}</div>
                <div>{item?.description}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.manufactuer_button}>
          <Button type="primary" size="large">
            Get Consumers
          </Button>
        </div>
      </div>
      <div className={styles.get_started_container}>
        <div className={styles.get_started_title}>Get started NOW!</div>
        <div>
          Join Indhan Bazaar today and embark on a journey of discovery,
          connection, and expansion.
        </div>
        <div>
          <Button
            style={{
              color: "#FD8C28",
            }}
            size="large"
          >
            Get started NOW!
          </Button>
        </div>
      </div>
      <Footer />
      <SubscriptionModal open={isSubModal} setOpen={onCancelSubModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = state.user.user;

  return {
    userDetails,
  };
};

export default connect(mapStateToProps, null)(Home);
